import { t } from 'i18next';

export const KERNEL_PRODUCT = 'KernelProduct';
export const DRIED_NUT_IN_SHELL_PRODUCT = 'DriedNutInShellProduct';

export const BEST_BEFORE_START_YEAR = 2022;
const currentYear = new Date().getFullYear();

export const bestBeforePastYearsCount = currentYear - BEST_BEFORE_START_YEAR;

export const REGION_AFRICA = 'Africa';
export const REGION_MIDDLE_EAST = 'Middle East';
export const REGION_PACIFIC = 'Pacific';
export const REGION_ASIA = 'Asia';
export const REGION_EUROPE = 'Europe';
export const REGION_NORTH_AMERICA = 'North America';
export const REGION_SOUTH_AMERICA = 'South America';
export const REGION_AUSTRALIA = 'Australia';

export const STYLE_0 = 'Style 0';
export const STYLE_1 = 'Style 1';
export const STYLE_1_S = 'Style 1S';
export const STYLE_2 = 'Style 2';
export const STYLE_3 = 'Style 3';
export const STYLE_4_L = 'Style 4L';
export const STYLE_4_S = 'Style 4S';
export const STYLE_5 = 'Style 5';
export const STYLE_6 = 'Style 6';
export const STYLE_7 = 'Style 7';
export const STYLE_8 = 'Style 8';
export const STYLE_CUSTOM = 'custom';

export const SIZE_18P = '<18mm';
export const SIZE_18_20 = '18-20mm';
export const SIZE_20_22 = '20-22mm';
export const SIZE_22_25 = '22-25mm';
export const SIZE_25 = '25mm+';
export const SIZE_22 = '22mm+';
export const SIZE_20 = '20mm+';
export const SIZE_18 = '18mm+';
export const SIZE_CUSTOM = 'other';

export const EXW = 'exw';
export const CFR = 'cfr';
export const DAP = 'dap';
export const DAYS_30 = '30 days';
export const DAYS_60 = '60 days';
export const DAYS_90 = '90 days';
export const DAYS_180 = '180 days';
export const CUSTOM_PAYMENT_OPTION = 'custom_payment_option';

export const PREMIUM = 'premium';
export const COMMERCIAL = 'commercial';
export const REJECTED = 'rejected';

export const HACCP_CERT = 'haccp'
export const BRC_CERT = 'brc'
export const FSSC_CERT = 'fssc'

export const PPECB = 'ppecb'
export const GLOBAL_GAP = 'global_gap'
export const GACC = 'gacc'
export const MRL = 'mrl'
export const ESG = 'esg'

export const BRC_AA_GRADE = 'aa_grade'
export const BRC_A_GRADE = 'a_grade'
export const BRC_B_GRADE = 'b_grade'
export const BRC_C_GRADE = 'c_grade'
export const BRC_D_GRADE = 'd_grade'
export const SKR_0 = '<25%';
export const SKR_25 = '>25%';
export const SKR_26 = '>26%';
export const SKR_27 = '>27%';
export const SKR_28 = '>28%';
export const SKR_29 = '>29%';
export const SKR_30 = '>30%';
export const SKR_31 = '>31%';
export const SKR_32 = '>32%';
export const SKR_33 = '>33%';
export const SKR_34 = '>34%';
export const SKR_35 = '>35%';

export const USKR_05 = '<0.5%';
export const USKR_1 = '<1%';
export const USKR_15 = '<1.5%';
export const USKR_2 = '<2%';
export const USKR_25 = '<2.5%';
export const USKR_3 = '<3%';
export const USKR_35 = '<3.5%';
export const USKR_4 = '<4%';
export const USKR_45 = '<4.5%';
export const USKR_5 = '<5%';
export const USKR_55 = '<5.5%';
export const USKR_6 = '<6%';
export const USKR_65 = '<6.5%';
export const USKR_7 = '<7%';
export const USKR_7P = '>7%';

export const BEAUMONT = 'Beaumont';
export const HYBRID = 'Hybrid';
export const INTEGRIFOLIA = 'Integrifolia';
export const OTHER_TREE_VARIANT = 'other_tree_variant';

export const OTHER_HYBRID_TREE_CULTIVAR = 'other_hybrid_tree_cultivar';
export const OTHER_INTEGRIFOLIA_TREE_CULTIVAR = 'other_integrifolia_tree_cultivar';

export const HALAAL = 'halaal'
export const KOSHER = 'kosher'
export const ORGANIC = 'organic'
export const PASTEURIZED = 'pasteurized'

export const regions = [
  { label: t('shared.product.regions.africa'), value: REGION_AFRICA },
  {
    label: t('shared.product.regions.middle_east'),
    value: REGION_MIDDLE_EAST,
  },
  { label: t('shared.product.regions.pacific'), value: REGION_PACIFIC },
  { label: t('shared.product.regions.asia'), value: REGION_ASIA },
  { label: t('shared.product.regions.europe'), value: REGION_EUROPE },
  {
    label: t('shared.product.regions.north_america'),
    value: REGION_NORTH_AMERICA,
  },
  {
    label: t('shared.product.regions.south_america'),
    value: REGION_SOUTH_AMERICA,
  },
  {
    label: t('shared.product.regions.australia'),
    value: REGION_AUSTRALIA,
  },
];

export const priceTypes = [
  {
    label: t('shared.product.price_type.exw'),
    value: EXW,
  },
  {
    label: t('shared.product.price_type.dap'),
    value: DAP,
  },
  {
    label: t('shared.product.price_type.cfr'),
    value: CFR,
  },
]

export const nutGrades = [
  {
    label: t('shared.product.nut_grade.premium'),
    value: PREMIUM,
  },
  {
    label: t('shared.product.nut_grade.commercial'),
    value: COMMERCIAL,
  },
  {
    label: t('shared.product.nut_grade.rejected'),
    value: REJECTED,
  },
];

export const nutStyles = [
  {
    label: t('shared.product.nut_style.style_0'),
    value: STYLE_0,
  },
  {
    label: t('shared.product.nut_style.style_1'),
    value: STYLE_1,
  },
  {
    label: t('shared.product.nut_style.style_1_s'),
    value: STYLE_1_S,
  },
  {
    label: t('shared.product.nut_style.style_2'),
    value: STYLE_2,
  },
  {
    label: t('shared.product.nut_style.style_3'),
    value: STYLE_3,
  },
  {
    label: t('shared.product.nut_style.style_4_l'),
    value: STYLE_4_L,
  },
  {
    label: t('shared.product.nut_style.style_4_s'),
    value: STYLE_4_S,
  },
  {
    label: t('shared.product.nut_style.style_5'),
    value: STYLE_5,
  },
  {
    label: t('shared.product.nut_style.style_6'),
    value: STYLE_6,
  },
  {
    label: t('shared.product.nut_style.style_7'),
    value: STYLE_7,
  },
  {
    label: t('shared.product.nut_style.style_8'),
    value: STYLE_8,
  },
];

export const sizes = [
  {
    label: t('shared.product.nut_size.size_18p'),
    value: SIZE_18P,
  },
  {
    label: t('shared.product.nut_size.size_18_20'),
    value: SIZE_18_20,
  },
  {
    label: t('shared.product.nut_size.size_20_22'),
    value: SIZE_20_22,
  },
  {
    label: t('shared.product.nut_size.size_22_25'),
    value: SIZE_22_25,
  },
  {
    label: t('shared.product.nut_size.size_25'),
    value: SIZE_25,
  },
  {
    label: t('shared.product.nut_size.size_22'),
    value: SIZE_22,
  },
  {
    label: t('shared.product.nut_size.size_20'),
    value: SIZE_20,
  },
  {
    label: t('shared.product.nut_size.size_18'),
    value: SIZE_18,
  },
];

export const unsoundKernelRecoveries = [
  {
    label: '<0.5%',
    value: USKR_05,
  },
  {
    label: '<1%',
    value: USKR_1,
  },
  {
    label: '<1.5%',
    value: USKR_15,
  },
  {
    label: '<2%',
    value: USKR_2,
  },
  {
    label: '<2.5%',
    value: USKR_25,
  },
  {
    label: '<3%',
    value: USKR_3,
  },
  {
    label: '<3.5%',
    value: USKR_35,
  },
  {
    label: '<4%',
    value: USKR_4,
  },
  {
    label: '<4.5%',
    value: USKR_45,
  },
  {
    label: '<5%',
    value: USKR_5,
  },
  {
    label: '<5.5%',
    value: USKR_55,
  },
  {
    label: '<6%',
    value: USKR_6,
  },
  {
    label: '<6.5%',
    value: USKR_65,
  },
  {
    label: '<7%',
    value: USKR_7,
  },
  {
    label: '>7%',
    value: USKR_7P,
  },
];

export const soundKernelRecoveries = [
  {
    label: '<25%',
    value: SKR_0,
  },
  {
    label: '>25%',
    value: SKR_25,
  },
  {
    label: '>26%',
    value: SKR_26,
  },
  {
    label: '>27%',
    value: SKR_27,
  },
  {
    label: '>28%',
    value: SKR_28,
  },
  {
    label: '>29%',
    value: SKR_29,
  },
  {
    label: '>30%',
    value: SKR_30,
  },
  {
    label: '>31%',
    value: SKR_31,
  },
  {
    label: '>32%',
    value: SKR_32,
  },
  {
    label: '>33%',
    value: SKR_33,
  },
  {
    label: '>34%',
    value: SKR_34,
  },
  {
    label: '>35%',
    value: SKR_35,
  },
];

export const treeVariants = [
  {
    label: t('shared.product.tree_variants.beaumont'),
    value: BEAUMONT,
  },
  {
    label: t('shared.product.tree_variants.hybrid'),
    value: HYBRID,
  },
  {
    label: t('shared.product.tree_variants.integrifolia'),
    value: INTEGRIFOLIA,
  },
];

export const hybridCultivars = [
  {
    label: "Nelmak2",
    value: "Nelmak2",
  },
  {
    label: "NelmakD",
    value: "NelmakD",
  },
];

export const integrifoliaCultivars = [
  {
    label: "816",
    value: "816",
  },
  {
    label: "814",
    value: "814",
  },
  {
    label: "849",
    value: "849",
  },
  {
    label: "842",
    value: "842",
  },
  {
    label: "A4",
    value: "A4",
  },
  {
    label: "A16",
    value: "A16",
  },
  {
    label: "A203",
    value: "A203",
  },
  {
    label: "A268",
    value: "A268",
  },
  {
    label: "744",
    value: "744",
  },
  {
    label: "788",
    value: "788",
  },
];

export const filterIntegrifoliaCultivars = [
  ...integrifoliaCultivars,
  {
    label: t("offer_listing.filter.tree_cultivars.integrifolia.other"),
    value: OTHER_INTEGRIFOLIA_TREE_CULTIVAR,
  },
];

export const filterHybridCultivars = [
  ...hybridCultivars,
  {
    label: t("offer_listing.filter.tree_cultivars.hybrid.other"),
    value: OTHER_HYBRID_TREE_CULTIVAR,
  },
];

export const cultivarsOptions = {
  [HYBRID]: hybridCultivars,
  [INTEGRIFOLIA]: integrifoliaCultivars,
};

export const seasons = [
  { label: currentYear + 1, value: currentYear + 1 },
  { label: currentYear, value: currentYear },
  { label: currentYear - 1, value: currentYear - 1 },
  { label: currentYear - 2, value: currentYear - 2 },
];

export const foodCertificates = [
  {
    label: t('shared.product.food_certificates.haccp'),
    value: HACCP_CERT,
  },
  {
    label: t('shared.product.food_certificates.fssc'),
    value: FSSC_CERT,
  },
  {
    label: t('shared.product.food_certificates.brc'),
    value: BRC_CERT,
  },
];

export const containerLoads = [
  {
    label: t('shared.product.container_load.fcl'),
    value: 'fcl',
  },
  {
    label: t('shared.product.container_load.lcl'),
    value: 'lcl',
  },
];

export const paymentOptions = [
  {
    label: t('shared.product.payment_options.dap'),
    value: DAP,
  },
  {
    label: t('shared.product.payment_options.30_days'),
    value: DAYS_30,
  },
  {
    label: t('shared.product.payment_options.60_days'),
    value: DAYS_60,
  },
  {
    label: t('shared.product.payment_options.90_days'),
    value: DAYS_90,
  },
  {
    label: t('shared.product.payment_options.180_days'),
    value: DAYS_180,
  },
];

export const brcLevelOptions = [
  {
    label: t('shared.product.brc_levels.aa_grade'),
    value: BRC_AA_GRADE,
  },
  {
    label: t('shared.product.brc_levels.a_grade'),
    value: BRC_A_GRADE,
  },
  {
    label: t('shared.product.brc_levels.b_grade'),
    value: BRC_B_GRADE,
  },
  {
    label: t('shared.product.brc_levels.c_grade'),
    value: BRC_C_GRADE,
  },
  {
    label: t('shared.product.brc_levels.d_grade'),
    value: BRC_D_GRADE,
  },
];

export const religionFields = [
  HALAAL,
  KOSHER,
]
