import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import { BUYER_ABLITY_SUBJECT, LISTING_ABILITY_SUBJECT, NEW_LISTING_ABILITY_SUBJECT } from "ability";
import { OFFER, ORDER, PUBLISHED, UNPUBLISHED } from "utils/constants/offerListings";
import {
  hasAdminRole,
  hasBuyerRole,
  hasNoRoles,
  hasPartnerRole,
  hasSellerRole,
  isCompanyOwner,
  isVerified,
} from "utils/helper/UserRoleValidation";

const defineAbilityFor = ({ can, cannot, userDetails, companyDetails }) => {
  can("follow", LISTING_ABILITY_SUBJECT, { status: PUBLISHED });

  can("edit", LISTING_ABILITY_SUBJECT, { status: UNPUBLISHED, 'creator.id': userDetails.id });
  can("delete", LISTING_ABILITY_SUBJECT, { status: UNPUBLISHED, 'creator.id': userDetails.id });
  can("rejectBid", LISTING_ABILITY_SUBJECT, { status: PUBLISHED, 'creator.id': userDetails.id, currentBidderId: { $ne: null } });
  can("unpublish", LISTING_ABILITY_SUBJECT, { status: PUBLISHED, 'creator.id': userDetails.id, currentBidderId: null });

  can("retractBid", LISTING_ABILITY_SUBJECT, { status: PUBLISHED, currentBidderId: userDetails.id });

  if (isCompanyOwner(userDetails, companyDetails)) {
    can("edit", LISTING_ABILITY_SUBJECT, { status: UNPUBLISHED, 'creator.companyId': companyDetails.id });
    can("delete", LISTING_ABILITY_SUBJECT, { status: UNPUBLISHED, 'creator.companyId': companyDetails.id });
    can("rejectBid", LISTING_ABILITY_SUBJECT, { status: PUBLISHED, 'creator.companyId': companyDetails.id, currentBidderId: { $ne: null } });
    can("unpublish", LISTING_ABILITY_SUBJECT, { status: PUBLISHED, 'creator.companyId': companyDetails.id, currentBidderId: null });    
  }

  if (hasBuyerRole(userDetails)) {
    can("create", NEW_LISTING_ABILITY_SUBJECT, { category: ORDER });
    can("viewPortalsFooter", LISTING_ABILITY_SUBJECT, { category: OFFER });
  }

  if (hasSellerRole(userDetails)) {
    can("create", NEW_LISTING_ABILITY_SUBJECT, { category: OFFER });
    can("viewPortalsFooter", LISTING_ABILITY_SUBJECT, { category: ORDER });
  }

  if (hasNoRoles(userDetails) || hasPartnerRole(userDetails)) {
    cannot("follow", LISTING_ABILITY_SUBJECT);
  }

  if (hasPartnerRole(userDetails)) {
    can("invite", BUYER_ABLITY_SUBJECT);
  }

  return null;
};

const defineAdminAbilityFor = ({ can }) => {
  can("unpublish", LISTING_ABILITY_SUBJECT, { status: PUBLISHED, currentBidderId: null });
  can("changePrice", LISTING_ABILITY_SUBJECT)

  return null;
}

const buildListingsAbility = ({ userDetails, companyDetails }) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

  if (isVerified(companyDetails)) {
    defineAbilityFor({ can, cannot, userDetails, companyDetails });
  } else if (hasAdminRole(userDetails)) {
    defineAdminAbilityFor({ can, cannot, userDetails, companyDetails });
  }

  return build();
};

export default buildListingsAbility;
