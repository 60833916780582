import React, { useContext, useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { OFFER, ORDER } from 'utils/constants/offerListings';
import { BID_PRICE_MAX_FRACTION, MINIMUM_PRICE_GAP } from 'utils/constants/bidding';
import { usePlaceBidMutation } from 'api/listings';
import { formatPrice } from 'utils/helper/Charts';
import Icon from 'components/BaseComponents/Icon';
import Input from 'components/BaseComponents/Input';
import IconButton from 'components/BaseComponents/IconButton';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import './styles.scss';
import Modal from 'components/BaseComponents/Modal';
import BidAcknowledgementModal from './BidAcknowledgementModal';

const PlaceCounterBidModal = ({ onModalClose }) => {
  const [counterPrice, setCounterPrice] = useState('');

  const { listingInformation, productInformation } = useContext(ListingDetailsContext);

  const [placeBid, { isLoading }] = usePlaceBidMutation();

  const onPlaceBid = () =>
    placeBid({ id: listingInformation.id, payload: { amount: counterPrice } });
  const offeredPriceInputHandler = (value) => setCounterPrice(value);

  const { offerPrice, offerVolume, priceType } = productInformation;
  const listingIsOfferType = listingInformation.category === OFFER;
  const listingIsOrderType = listingInformation.category === ORDER;
  const hasExistingBid = listingInformation.currentBidderId;
  const activePrice = hasExistingBid ? listingInformation.highestBidPrice : offerPrice;

  const offerOverPriceWarning = Number(counterPrice) > Number(offerPrice);
  const showOfferOverPriceWarning = listingIsOfferType && offerOverPriceWarning;
  const minimunOfferPriceForBidding = Number(activePrice) + MINIMUM_PRICE_GAP;
  const offerUnderPriceError =
    Number(counterPrice) < minimunOfferPriceForBidding.toFixed(BID_PRICE_MAX_FRACTION);
  const showOfferUnderPriceError = listingIsOfferType && hasExistingBid && offerUnderPriceError;

  const orderUnderPriceWarning = Number(counterPrice) < Number(offerPrice);
  const showOrderUnderPriceWarning = listingIsOrderType && orderUnderPriceWarning;
  const maxOrderPriceForBidding = Number(activePrice) - MINIMUM_PRICE_GAP;
  const orderOverPriceError =
    Number(counterPrice) > maxOrderPriceForBidding.toFixed(BID_PRICE_MAX_FRACTION);
  const showOrderOverPriceError = listingIsOrderType && hasExistingBid && orderOverPriceError;

  const isWarningTooltipVisible = showOfferOverPriceWarning || showOrderUnderPriceWarning;
  const isErrorTooltipVisible = showOfferUnderPriceError || showOrderOverPriceError;

  const [isAcknowledgementModalVisible, setIsAcknowledgementModalVisible] = useState(false);
  const onAcknowledgementModalOpen = () => setIsAcknowledgementModalVisible(true);
  const onAcknowledgementModalClose = () => setIsAcknowledgementModalVisible(false);

  const totalBatchPriceBreakdown = counterPrice?.length
    ? `(${offerVolume} kg x ${t(`shared.price.${priceType}`)} ${counterPrice || 0.0} ${t('bidding_process.per_kg')})`
    : `(${offerVolume} kg x ${t(`shared.price.${priceType}`)} 0.00 ${t('bidding_process.per_kg')})`;
  const counterBatchPriceTotal = counterPrice?.length
    ? `${t(`shared.price.${priceType}`)} ${formatPrice(offerVolume * counterPrice)}`
    : `${t(`shared.price.${priceType}`)} 0.00`;
  const isBidButtonDisabled = !counterPrice.length || isErrorTooltipVisible || isLoading;

  const generateHints = () => {
    if (isErrorTooltipVisible) {
      return t(`bidding_process.${listingInformation.category}_price_error`);
    }

    if (isWarningTooltipVisible) {
      return t(`bidding_process.${listingInformation.category}_price_warning`);
    }

    return '';
  };

  return (
    <div className="place-counter-bid-modal">
      <div className="place-counter-bid-modal__header">
        <div className="place-counter-bid-modal__header--text">
          {t('bidding_process.propose_your_counter_offer')}
        </div>
        <IconButton icon={<Icon name="close" />} size="tiny" onClick={onModalClose} />
      </div>
      <div className="place-counter-bid-modal__offer-price">
        <Input
          className={classNames('place-counter-bid-modal__offer-price-input', {
            'place-counter-bid-modal__offer-price-input--message': hasExistingBid,
            'place-counter-bid-modal__offer-price-input--error': isErrorTooltipVisible,
            'place-counter-bid-modal__offer-price-input--warning': isWarningTooltipVisible,
          })}
          size="small"
          label={t('bidding_process.new_offer_price_placeholder')}
          placeholder={t('bidding_process.new_offer_price_placeholder')}
          required={true}
          value={counterPrice}
          onChange={offeredPriceInputHandler}
          error={generateHints()}
          touched={counterPrice?.length > 0}
          type="number"
        />
        {hasExistingBid && (
          <div
            className={classNames({
              'place-counter-bid-modal__offer-price-warning': hasExistingBid,
            })}
          >
            <Icon name="info" />
            <span>{t(`bidding_process.${listingInformation.category}_price_error`)}</span>
          </div>
        )}
      </div>
      <div className="place-counter-bid-modal__total">
        <div className="flex justify-between">
          <div className="place-counter-bid-modal__total--text">{t('bidding_process.total')}</div>
          <div className="place-counter-bid-modal__total--price">{counterBatchPriceTotal}</div>
        </div>
        <div className="place-counter-bid-modal__total--breakdown">{totalBatchPriceBreakdown}</div>
      </div>

      <Modal
        isVisible={isAcknowledgementModalVisible}
        onOutsideClick={onAcknowledgementModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <BidAcknowledgementModal
            onModalClose={onAcknowledgementModalClose}
            onAcknowledged={onPlaceBid}
            price={counterPrice}
            volume={offerVolume}
            priceType={priceType}/>
        </Modal.Content>
      </Modal>

      <div className="place-counter-bid-modal__cta">
        <button
          className="place-counter-bid-modal__cta-cancel"
          type="button"
          onClick={onModalClose}
        >
          {t('bidding_process.cancel')}
        </button>
        <button
          className={classNames('place-counter-bid-modal__cta-bid', {
            'place-counter-bid-modal__cta-bid--disabled': isBidButtonDisabled,
          })}
          type="button"
          disabled={isBidButtonDisabled}
          onClick={onAcknowledgementModalOpen}
        >
          {t('bidding_process.bid')}
        </button>
      </div>
    </div>
  );
};

PlaceCounterBidModal.propTypes = {
  onModalClose: PropTypes.func.isRequired,
};

export default PlaceCounterBidModal;
