import React from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { termsAndConditionPDF } from './pdf';

export const orderSteps = [
  { stepName: t('offer_creation.stepper.product_details') },
  { stepName: t('offer_creation.stepper.review_listing') },
  { stepName: t('offer_creation.stepper.decleration') },
];

export const offerSteps = [
  { stepName: t('offer_creation.stepper.product_details') },
  { stepName: t('offer_creation.stepper.review_listing') },
  { stepName: t('offer_creation.stepper.food_safety') },
  { stepName: t('offer_creation.stepper.decleration') },
];

export const offerOrderPageIndex = {
  productDetails: 0,
  reviewListing: 1,
  foodSafety: 2,
  declaration: (isOffer) => (isOffer ? 3 : 2),
};

export const kernelHelpDocs = [
  {
    label: 'Macadamia Test Requirements.pdf',
    link: 'https://macsm.org/KernelSpecs',
  },
  {
    label: 'WMO Macadamia Kernel Product Standard August 2023.pdf',
    link: 'https://macsm.org/WMO-Kernel-Standard',
  },
  {
    label: 'SAMAC MRL October 2023.pdf',
    link: 'https://macsm.org/SAMAC-MRL',
  },
];

export const dnisHelpDocs = [
  {
    label: 'MSM DNIS STANDARDS AND SPECIFICATIONS.pdf',
    link: 'https://macsm.org/DNISSpecs',
  },
];

export const microbiologicalTestTableHeaders = [
  'Organism',
  'Test Method',
  'Test Count (cfu/g)',
  'Max Limit',
];
export const chemicalTestTableHeaders = ['Chemical', 'Test Method', 'Test Count', 'Max Limit'];
export const heavyMetalTestTableHeaders = [
  'Metal',
  'Test Method',
  'Test Count (mg/kg)',
  'Max Limit',
];

export const foodSafetyTestSubstancesValues = {
  E_COLI: 'e_coli',
  SALMONELLA: 'salmonella',
  PLATE_COUNT: 'plate_count',
  YEAST: 'yeast',
  MOULD: 'mould',
  CALIFORMS_0_4: 'coliforms_0_4',
  CALIFORMS_5_8: 'coliforms_5_8',
  LYSTERIA_MONOCYTOGENES: 'lysteria_monocytogenes',
  STAPHYLOCOCCUS_AUREUS: 'staphylococcus_aureus',
  ENTEROBACTERIA: 'enterobacteria',
  B_CEREUS: 'b_cereus',
  TOTAL_AFLATOXIN: 'total_aflatoxin',
  AFLATOXIN_B1: 'aflatoxin_b1',
  FREE_FATTY_ACID: 'free_fatty_acid',
  PEROXIDE_VALUE_2_YEAR: 'peroxide_value_2_year',
  PEROXIDE_VALUE_1_YEAR: 'peroxide_value_1_year',
  LEAD: 'lead',
  MERCURY: 'mercury',
  CADMIUM: 'cadmium',
  ARSENIC: 'arsenic',
};

export const microbiologicalTestTableContents = [
  {
    substance: 'E-Coli',
    substanceValue: foodSafetyTestSubstancesValues.E_COLI,
    testMethods: [
      {
        value: 'iso_16649_1',
        label: t('offer_creation.food_safety.test_methods.iso_16649_1.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.iso_16649_1.max_limit'),
      },
      {
        value: 'as_5013_15_2006',
        label: t('offer_creation.food_safety.test_methods.as_5013_15_2006.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.as_5013_15_2006.max_limit'),
      },
    ],
    testMethodFieldName: 'e_coli_test_method',
    testValueFieldName: 'e_coli_test_value',
  },
  {
    substance: 'Salmonella',
    substanceValue: foodSafetyTestSubstancesValues.SALMONELLA,
    testMethods: [
      {
        value: 'iso_16649_2',
        label: t('offer_creation.food_safety.test_methods.iso_16649_2.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.iso_16649_2.max_limit'),
      },
      {
        value: 'as_5013_10_2009',
        label: t('offer_creation.food_safety.test_methods.as_5013_10_2009.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.as_5013_10_2009.max_limit'),
      },
    ],
    testMethodFieldName: 'salmonella_test_method',
    testValueFieldName: 'salmonella_test_value',
  },
  {
    substance: 'Plate Count',
    substanceValue: foodSafetyTestSubstancesValues.PLATE_COUNT,
    testMethods: [
      {
        value: 'aoac_990_12',
        label: t('offer_creation.food_safety.test_methods.aoac_990_12.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.aoac_990_12.max_limit'),
      },
      {
        value: 'as_5013_1',
        label: t('offer_creation.food_safety.test_methods.as_5013_1.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.as_5013_1.max_limit'),
      },
    ],
    testMethodFieldName: 'plate_count_test_method',
    testValueFieldName: 'plate_count_test_value',
  },
  {
    substance: 'Yeast',
    substanceValue: foodSafetyTestSubstancesValues.YEAST,
    testMethods: [
      {
        value: 'as_17662_2_2009_1',
        label: t('offer_creation.food_safety.test_methods.as_17662_2_2009_1.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.as_17662_2_2009_1.max_limit'),
      },
    ],
    testMethodFieldName: 'yeast_test_method',
    testValueFieldName: 'yeast_test_value',
  },
  {
    substance: 'Mould',
    substanceValue: foodSafetyTestSubstancesValues.MOULD,
    testMethods: [
      {
        value: 'as_17662_2_2009_2',
        label: t('offer_creation.food_safety.test_methods.as_17662_2_2009_2.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.as_17662_2_2009_2.max_limit'),
      },
    ],
    testMethodFieldName: 'mould_test_method',
    testValueFieldName: 'mould_test_value',
  },
  {
    substance: 'Coliforms (Style 0-4)',
    substanceValue: foodSafetyTestSubstancesValues.CALIFORMS_0_4,
    testMethods: [
      {
        value: 'iso_4832_2006',
        label: t('offer_creation.food_safety.test_methods.iso_4832_2006.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.iso_4832_2006.max_limit'),
      },
    ],
    testMethodFieldName: 'coliforms_0_4_test_method',
    testValueFieldName: 'coliforms_0_4_test_value',
  },
  {
    substance: 'Coliforms (Style 5-8)',
    substanceValue: foodSafetyTestSubstancesValues.CALIFORMS_5_8,
    testMethods: [
      {
        value: 'iso_4832_2007',
        label: t('offer_creation.food_safety.test_methods.iso_4832_2007.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.iso_4832_2007.max_limit'),
      },
    ],
    testMethodFieldName: 'coliforms_5_8_test_method',
    testValueFieldName: 'coliforms_5_8_test_value',
  },
  {
    substance: 'Lysteria Monocytogenes',
    substanceValue: foodSafetyTestSubstancesValues.LYSTERIA_MONOCYTOGENES,
    testMethods: [
      {
        value: 'wi_an_079',
        label: t('offer_creation.food_safety.test_methods.wi_an_079.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.wi_an_079.max_limit'),
      },
    ],
    testMethodFieldName: 'lysteria_monocytogenes_test_method',
    testValueFieldName: 'lysteria_monocytogenes_test_value',
  },
  {
    substance: 'Staphylococcus aureus',
    substanceValue: foodSafetyTestSubstancesValues.STAPHYLOCOCCUS_AUREUS,
    testMethods: [
      {
        value: 'iso_6888_1999',
        label: t('offer_creation.food_safety.test_methods.iso_6888_1999.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.iso_6888_1999.max_limit'),
      },
    ],
    testMethodFieldName: 'staphylococcus_aureus_test_method',
    testValueFieldName: 'staphylococcus_aureus_test_value',
  },
  {
    substance: 'Enterobacteria',
    substanceValue: foodSafetyTestSubstancesValues.ENTEROBACTERIA,
    testMethods: [
      {
        value: 'iso_21528_1999',
        label: t('offer_creation.food_safety.test_methods.iso_21528_1999.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.iso_21528_1999.max_limit'),
      },
    ],
    testMethodFieldName: 'enterobacteria_test_method',
    testValueFieldName: 'enterobacteria_test_value',
  },
  {
    substance: 'B. Cereus',
    substanceValue: foodSafetyTestSubstancesValues.B_CEREUS,
    testMethods: [
      {
        value: 'iso_7932_2004',
        label: t('offer_creation.food_safety.test_methods.iso_7932_2004.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.iso_7932_2004.max_limit'),
      },
    ],
    testMethodFieldName: 'b_cereus_test_method',
    testValueFieldName: 'b_cereus_test_value',
  },
];

export const chemicalTestTableContents = [
  {
    substance: 'Total Aflatoxin',
    substanceValue: foodSafetyTestSubstancesValues.TOTAL_AFLATOXIN,
    testMethods: [
      {
        value: 'wi_an_017_hplc_1',
        label: t('offer_creation.food_safety.test_methods.wi_an_017_hplc_1.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.wi_an_017_hplc_1.max_limit'),
      },
      {
        value: 'wi_an_017_hplc_1',
        label: 'AOAC 991.31',
        maxLimit: t('offer_creation.food_safety.test_methods.wi_an_017_hplc_1.max_limit'),
      },
    ],
    testMethodFieldName: 'total_aflatoxin_test_method',
    testValueFieldName: 'total_aflatoxin_test_value',
    // turned off due to some business policies, but might be enabled later
    // cclCheckboxFieldName: 'total_aflatoxin_ccl_checkbox',
    // cclMaxLimitFieldName: 'total_aflatoxin_ccl_value',
  },
  {
    substance: 'Aflatoxin B1',
    substanceValue: foodSafetyTestSubstancesValues.AFLATOXIN_B1,
    testMethods: [
      {
        value: 'wi_an_017_hplc_2',
        label: t('offer_creation.food_safety.test_methods.wi_an_017_hplc_2.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.wi_an_017_hplc_2.max_limit'),
      },
      {
        value: 'wi_an_017_hplc_2',
        label: 'AOAC 991.31',
        maxLimit: t('offer_creation.food_safety.test_methods.wi_an_017_hplc_2.max_limit'),
      },
    ],
    testMethodFieldName: 'aflatoxin_b1_test_method',
    testValueFieldName: 'aflatoxin_b1_test_value',
    // turned off due to some business policies, but might be enabled later
    // cclCheckboxFieldName: 'aflatoxin_b1_ccl_checkbox',
    // cclMaxLimitFieldName: 'aflatoxin_b1_ccl_value',
  },
  {
    substance: 'Free Fatty Acid',
    substanceValue: foodSafetyTestSubstancesValues.FREE_FATTY_ACID,
    testMethods: [
      {
        value: 'aocs_ca_5a_40',
        label: t('offer_creation.food_safety.test_methods.aocs_ca_5a_40.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.aocs_ca_5a_40.max_limit'),
      },
    ],
    testMethodFieldName: 'free_fatty_acid_test_method',
    testValueFieldName: 'free_fatty_acid_test_value',
  },
  {
    substance: 'Peroxide Value (2 year shelf life)',
    substanceValue: foodSafetyTestSubstancesValues.PEROXIDE_VALUE_2_YEAR,
    testMethods: [
      {
        value: 'aocs_cd_8b_90_1',
        label: t('offer_creation.food_safety.test_methods.aocs_cd_8b_90_1.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.aocs_cd_8b_90_1.max_limit'),
      },
    ],
    testMethodFieldName: 'peroxide_value2_test_method',
    testValueFieldName: 'peroxide_value2_test_value',
  },
  {
    substance: 'Peroxide Value (1 year shelf life)',
    substanceValue: foodSafetyTestSubstancesValues.PEROXIDE_VALUE_1_YEAR,
    testMethods: [
      {
        value: 'aocs_cd_8b_90_2',
        label: t('offer_creation.food_safety.test_methods.aocs_cd_8b_90_2.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.aocs_cd_8b_90_2.max_limit'),
      },
    ],
    testMethodFieldName: 'peroxide_value1_test_method',
    testValueFieldName: 'peroxide_value1_test_value',
  },
];

export const heavyMetalTestTableContents = [
  {
    substance: 'Lead (Pb)',
    substanceValue: foodSafetyTestSubstancesValues.LEAD,
    testMethods: [
      {
        value: 'iso_6633_1984',
        label: t('offer_creation.food_safety.test_methods.iso_6633_1984.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.iso_6633_1984.max_limit'),
      },
    ],
    testMethodFieldName: 'lead_test_method',
    testValueFieldName: 'lead_test_value',
  },
  {
    substance: 'Mercury (Hg)',
    substanceValue: foodSafetyTestSubstancesValues.MERCURY,
    testMethods: [
      {
        value: 'iso_6637_1984',
        label: t('offer_creation.food_safety.test_methods.iso_6637_1984.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.iso_6637_1984.max_limit'),
      },
    ],
    testMethodFieldName: 'mercury_test_method',
    testValueFieldName: 'mercury_test_value',
  },
  {
    substance: 'Cadmium (Cd)',
    substanceValue: foodSafetyTestSubstancesValues.CADMIUM,
    testMethods: [
      {
        value: 'iso_6561_2005',
        label: t('offer_creation.food_safety.test_methods.iso_6561_2005.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.iso_6561_2005.max_limit'),
      },
    ],
    testMethodFieldName: 'cadmium_test_method',
    testValueFieldName: 'cadmium_test_value',
  },
  {
    substance: 'Arsenic (As)',
    substanceValue: foodSafetyTestSubstancesValues.ARSENIC,
    testMethods: [
      {
        value: 'aoac_2013_06_icp_ms',
        label: t('offer_creation.food_safety.test_methods.aoac_2013_06_icp_ms.method'),
        maxLimit: t('offer_creation.food_safety.test_methods.aoac_2013_06_icp_ms.max_limit'),
      },
    ],
    testMethodFieldName: 'arsenic_test_method',
    testValueFieldName: 'arsenic_test_value',
  },
];

export const microbiologicalTestCertificate = {
  title: t('offer_creation.food_safety.microbiological_test_title'),
  fieldName: 'microbiological_certificate',
};

export const chemicalTestCertificate = {
  title: t('offer_creation.food_safety.chemical_test_title'),
  fieldName: 'chemical_certificate',
};

export const ppecbCertificate = {
  title: t('offer_creation.food_safety.ppecb_certificate_title'),
  fieldName: 'ppecb_certificate',
};

export const globalGapCertificate = {
  title: t('offer_creation.food_safety.global_gap_certificate_title'),
  fieldName: 'global_gap_certificate',
};

export const listingOrganicCertificates = {
  title: t('offer_creation.food_safety.listing_organic_certificates_title'),
  fieldName: 'listing_organic_certificates',
};

export const gaccCertificate = {
  title: t('offer_creation.food_safety.gacc_certificate_title'),
  fieldName: 'gacc_certificate',
};

export const mrlResults = {
  title: t('offer_creation.food_safety.mrl_results_title'),
  fieldName: 'mrl_results',
};

export const esgCertificate = {
  title: t('offer_creation.food_safety.esg_certificate_title'),
  fieldName: 'esg_certificate',
};

export const heavyMetalTestCertificate = {
  title: t('offer_creation.food_safety.heavy_metal_test_title'),
  fieldName: 'heavy_metal_certificate',
};

export const maxResidueLevelsTestCertificate = {
  title: t('offer_creation.food_safety.max_residue_levels_title'),
  fieldName: 'max_residue_certificate',
};

export const declarationContentsSellerCompletedDeclaration = [
  /* eslint-disable */
  {
    fieldName: 'terms_conditions',
    fieldText: (
      <Trans
        i18nKey="offer_creation.declaration.completed_declaration.list1"
        components={{
          declarationLink: (
            <a
              href={termsAndConditionPDF}
              target="_blank"
              className="offer-declaration__text--link"
            />
          ),
        }}
      />
    ),
  },
  {
    fieldName: 'author_listing',
    fieldText: t('offer_creation.declaration.completed_declaration.list2'),
  },
  {
    fieldName: 'quality_standards',
    fieldText: (
      <Trans
        i18nKey="offer_creation.declaration.completed_declaration.list3"
        components={{
          declarationLink: (
            <a
              href="/msm-macadamia-kernel-product-standard-and-specification.pdf"
              target="_blank"
              className="offer-declaration__text--link"
            />
          ),
        }}
      />
    ),
  },
  {
    fieldName: 'food_safety_standards',
    fieldText: (
      <Trans
        i18nKey="offer_creation.declaration.completed_declaration.list4"
        components={{
          declarationLink: (
            <a
              href="/msm-macadamia-kernel-product-standard-and-specification.pdf"
              target="_blank"
              className="offer-declaration__text--link"
            />
          ),
        }}
      />
    ),
  },
];

export const declarationContentsBuyerCompletedDeclaration = [
  {
    fieldName: 'terms_conditions',
    fieldText: (
      <Trans
        i18nKey="offer_creation.declaration.completed_declaration.list1"
        components={{
          declarationLink: (
            <a
              href={termsAndConditionPDF}
              target="_blank"
              className="offer-declaration__text--link"
            />
          ),
        }}
      />
    ),
  },
  {
    fieldName: 'author_listing',
    fieldText: t('offer_creation.declaration.completed_declaration.list2_buyer'),
  },
];

export const getDeclarationContentsSkippedDeclaration = (priorDate, estimatedShippingDate) => [
  {
    fieldName: 'terms_conditions',
    fieldText: (
      <Trans
        i18nKey="offer_creation.declaration.completed_declaration.list1"
        components={{
          declarationLink: (
            <a
              href={termsAndConditionPDF}
              target="_blank"
              className="offer-declaration__text--link"
            />
          ),
        }}
      />
    ),
  },
  {
    fieldName: 'author_listing',
    fieldText: t('offer_creation.declaration.completed_declaration.list2'),
  },
  {
    fieldName: 'quality_standards',
    fieldText: (
      <Trans
        i18nKey="offer_creation.declaration.completed_declaration.list3"
        components={{
          declarationLink: (
            <a
              href="/msm-macadamia-kernel-product-standard-and-specification.pdf"
              target="_blank"
              className="offer-declaration__text--link"
            />
          ),
        }}
      />
    ),
  },
  {
    fieldName: 'compliance_date',
    fieldText: (
      <Trans
        i18nKey="offer_creation.declaration.skipped_declaration.list4"
        components={{
          declarationBoldText: <span className="offer-declaration__text--bold" />,
          priorDate,
        }}
      />
    ),
  },
  {
    fieldName: 'shipment_date',
    fieldText: (
      <Trans
        i18nKey="offer_creation.declaration.skipped_declaration.list5"
        components={{
          declarationBoldText: <span className="offer-declaration__text--bold" />,
          priorDate,
          shipmentDate: estimatedShippingDate,
        }}
      />
    ),
  },
];

export const getDeclarationContentsSellerAuthorization = (
  userFirstName,
  userLastName,
  companyName,
) => [
  {
    fieldName: 'user_company',
    fieldText: (
      <Trans
        i18nKey="offer_creation.declaration.authorization.list1"
        components={{
          declarationBoldText: <span className="offer-declaration__text--bold" />,
          userName: `${userFirstName} ${userLastName}`,
          companyName,
        }}
      />
    ),
  },
  {
    fieldName: 'accurate_true',
    fieldText: t('offer_creation.declaration.authorization.list2'),
  },
];

export const getDeclarationContentsBuyerAuthorization = (
  userFirstName,
  userLastName,
  companyName,
) => [
  {
    fieldName: 'user_company',
    fieldText: (
      <Trans
        i18nKey="offer_creation.declaration.authorization.list1_buyer"
        components={{
          declarationBoldText: <span className="offer-declaration__text--bold" />,
          userName: `${userFirstName} ${userLastName}`,
          companyName,
        }}
      />
    ),
  },
  {
    fieldName: 'accurate_true',
    fieldText: t('offer_creation.declaration.authorization.list2_buyer'),
  },
];
