import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProductCard from '../ProductCard';

const ListingCardContainer = ({ isUserVerified, listings, currency }) => {
  const { t } = useTranslation();
  let dispalyedListings = listings;
  if (!isUserVerified) {
    dispalyedListings = listings.slice(0, 10);
  }

  const generateRoute = (productId) => {
    if (isUserVerified) {
      return productId;
    }

    return null;
  };

  return (
    <Fragment>
      {dispalyedListings.map(
        ({
          id: productId,
          offerVolume,
          nutGrade,
          nutStyle,
          countryOfOrigin,
          offerPrice,
          priceType,
          estimatedShippingDate,
          bestBeforeDate,
          fullBatchPrice,
          size,
          type,
          soundKernelRecovery,
          unsoundKernelRecovery,
          listing: { serialNumber, category, status } = {},
        }) => (
          <ProductCard
            key={productId}
            quantity={offerVolume}
            quality={nutGrade}
            style={nutStyle}
            size={size}
            type={type}
            soundKernelRecovery={soundKernelRecovery}
            unsoundKernelRecovery={unsoundKernelRecovery}
            soundKernelRecoveryLabel={t('shared.product_card.sound_kernel_recovery_label')}
            unsoundKernelRecoveryLabel={t('shared.product_card.unsound_kernel_recovery_label')}
            country={countryOfOrigin}
            priceLabel={t('shared.product_card.price_label')}
            perKgPrice={offerPrice}
            isIdVisible={isUserVerified}
            idLabel={t(`shared.product_card.${category}_id_label`)}
            id={serialNumber}
            esdLabel={t('shared.product_card.esd_label')}
            esdDate={estimatedShippingDate}
            bestBeforeLabel={t('shared.product_card.best_before_label')}
            bestBeforeDate={bestBeforeDate}
            batchLabel={t('shared.product_card.batch_label')}
            batchPrice={fullBatchPrice}
            priceType={priceType}
            currency={currency}
            category={category}
            status={status}
            redirectLink={generateRoute(`${productId}`)}
          />
        ),
      )}
    </Fragment>
  );
};

ListingCardContainer.defaultProps = {
  isUserVerified: false,
  listings: [],
  currency: 'USD',
};

ListingCardContainer.propTypes = {
  isUserVerified: PropTypes.bool,
  listings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      offerVolume: PropTypes.string.isRequired,
      nutGrade: PropTypes.string,
      countryOfOrigin: PropTypes.string,
      offerPrice: PropTypes.string.isRequired,
      priceType: PropTypes.string.isRequired,
      estimatedShippingDate: PropTypes.string,
      bestBeforeDate: PropTypes.string.isRequired,
      fullBatchPrice: PropTypes.string.isRequired,
      redirectLink: PropTypes.string,
    }),
  ),
  currency: PropTypes.string,
};

export default ListingCardContainer;
