import React, { useRef, useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useUpdateProductPriceMutation } from 'api/products';
import Icon from 'components/BaseComponents/Icon';
import Input from 'components/BaseComponents/Input';
import IconButton from 'components/BaseComponents/IconButton';
import Modal from 'components/BaseComponents/Modal';
import { priceTypes } from 'utils/constants/product';
import { formatPrice } from 'utils/helper/Charts';
import SelectBox from 'components/BaseComponents/SelectBox';
import CustomToastify from 'components/Shared/ToastNotification/CustomToastify';
import ToastNotification from 'components/Shared/ToastNotification';
import { localizedSelectedObject } from 'utils/helper/selectBoxUtils';
import './styles.scss';

const ChangePrice = ({ productInformation }) => {
  const toastRef = useRef();

  const priceType = localizedSelectedObject(
    `shared.product.price_type.${productInformation.priceType}`,
    productInformation.priceType,
  );

  const [newPrice, setPrice] = useState(productInformation.offerPrice);
  const [newPriceType, setPriceType] = useState(priceType);
  const [error, setError] = useState(null);

  const [updateProductPrice, { isLoading }] = useUpdateProductPriceMutation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const priceText = t(`shared.price.${newPriceType?.value || "usd"}`)

  const totalBatchPriceBreakdown = newPrice?.length
    ? `(${productInformation.offerVolume} kg x ${priceText} ${newPrice || 0.0} ${t('bidding_process.per_kg')})`
    : `(${productInformation.offerVolume} kg x ${priceText} 0.00 ${t('bidding_process.per_kg')})`;
  const counterBatchPriceTotal = newPrice?.length
    ? `${priceText} ${formatPrice(productInformation.offerVolume * newPrice)}`
    : `${priceText} 0.00`;

  const onUpdatePrice = () => {
    const payload = { offer_price: newPrice, price_type: newPriceType?.value || '' };

    updateProductPrice({ productId: productInformation.id, payload })
      .unwrap()
      .then(() => {
        toastRef?.current?.notify(
          <CustomToastify type="success" message={t('bidding_process.change_price.success')} />,
        );
        closeModal();
      })
      .catch((res) => {
        setError(res?.data?.message);
        toastRef?.current?.notify(
          <CustomToastify type="error" message={t('bidding_process.something_went_wrong')} />,
        );
      });
  }

  return (
    <>
      <ToastNotification toastRef={toastRef} />
      <button
        className="current-bid-body--accept-bid"
        type="button"
        onClick={openModal}
      >
        {t('bidding_process.change_price.button')}
      </button>

      <Modal
        isVisible={isModalVisible}
        onOutsideClick={closeModal}
        className="progress-card-modal"
      >
        <Modal.Content>
          <div className="change-price-modal">
            <div className="change-price-modal__header">
              <div className="change-price-modal__header--text">
                {t('bidding_process.change_price.title')}
              </div>
              <IconButton icon={<Icon name="close" />} size="tiny" onClick={closeModal} />
            </div>
            {error && <div className="change-price-modal__base-error">{error}</div>}
            <div className="change-price-modal__form flex gap-20px padding-t-16px p-4">
              <div className="w-full">
                <Input
                  className="change-price-modal__offer-price-input"
                  size="small"
                  label={t(`bidding_process.change_price.price.${productInformation.category}.label`)}
                  placeholder={t(`bidding_process.change_price.price.${productInformation.category}.placeholder`)}
                  value={newPrice}
                  onChange={setPrice}
                  width="full"
                  type="number"
                />
              </div>
              <div className="w-full">
                <SelectBox
                  label={t('bidding_process.change_price.price_type.label')}
                  placeholderText={t('bidding_process.change_price.price_type.placeholder')}
                  options={priceTypes}
                  value={newPriceType}
                  // errorMsg={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                  onChange={setPriceType}
                  isSearchable={false}
                  width="full"
                  className="product-details-form__price-type"
                />
              </div>
            </div>
            <div className="change-price-modal__total">
              <div className="flex justify-between">
                <div className="change-price-modal__total--text">{t('bidding_process.change_price.total')}</div>
                <div className="change-price-modal__total--price">{counterBatchPriceTotal}</div>
              </div>
              <div className="change-price-modal__total--breakdown">{totalBatchPriceBreakdown}</div>
            </div>

            <div className="change-price-modal__cta">
              <button
                className="change-price-modal__cta-cancel"
                type="button"
                onClick={closeModal}
              >
                {t('bidding_process.change_price.cancel')}
              </button>
              <button
                className="change-price-modal__cta-update"
                type="button"
                disabled={isLoading}
                onClick={onUpdatePrice}
              >
                {t('bidding_process.change_price.button')}
              </button>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

ChangePrice.propTypes = {
  productInformation: PropTypes.shape({
    id: PropTypes.number,
    offerVolume: PropTypes.string,
    offerPrice: PropTypes.string,
    priceType: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
};

export default ChangePrice;
