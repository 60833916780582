import { t } from 'i18next';
import { currentYear } from 'utils/helper/FormatDate';
import {
  DAP,
  DAYS_30,
  DAYS_60,
  DAYS_90,
  DAYS_180,
  CUSTOM_PAYMENT_OPTION,
  STYLE_0,
  STYLE_1,
  STYLE_1_S,
  STYLE_2,
  STYLE_3,
  STYLE_4_L,
  STYLE_4_S,
  STYLE_5,
  STYLE_6,
  STYLE_7,
  STYLE_8,
  STYLE_CUSTOM,
  BRC_AA_GRADE,
  BRC_A_GRADE,
  BRC_B_GRADE,
  BRC_C_GRADE,
  BRC_D_GRADE,
  SIZE_18P,
  SIZE_18_20,
  SIZE_20_22,
  SIZE_22_25,
  SIZE_25,
  SIZE_22,
  SIZE_20,
  SIZE_18,
  SIZE_CUSTOM,
  SKR_0,
  SKR_25,
  SKR_26,
  SKR_27,
  SKR_28,
  SKR_29,
  SKR_30,
  SKR_31,
  SKR_32,
  SKR_33,
  SKR_34,
  SKR_35,
  USKR_05,
  USKR_1,
  USKR_15,
  USKR_2,
  USKR_25,
  USKR_3,
  USKR_35,
  USKR_4,
  USKR_45,
  USKR_5,
  USKR_55,
  USKR_6,
  USKR_65,
  USKR_7,
  USKR_7P,
  PREMIUM,
  COMMERCIAL,
  REJECTED,
} from './product';

export const HANDLE_SORT_CHANGE = 'HANDLE_SORT_CHANGE';
export const HANDLE_MY_LISTINGS_SORT_CHANGE = 'HANDLE_MY_LISTINGS_SORT_CHANGE';
export const HANDLE_PAGE_CHANGE = 'HANDLE_PAGE_CHANGE';
export const HANDLE_MY_LISTINGS_CHANGE = 'HANDLE_MY_LISTINGS_CHANGE';
export const HANDLE_LISTING_TYPE_CHANGE = 'HANDLE_LISTING_TYPE_CHANGE';
export const HANDLE_PRODUCT_TYPE_CHANGE = 'HANDLE_PRODUCT_TYPE_CHANGE';
export const HANDLE_LISTING_DATE_CHANGE = 'HANDLE_LISTING_DATE_CHANGE';
export const HANDLE_HACCP_CERTIFICATION_CHANGE = 'HANDLE_HACCP_CERTIFICATION_CHANGE';
export const HANDLE_FSSC_CERTIFICATION_CHANGE = 'HANDLE_FSSC_CERTIFICATION_CHANGE';
export const HANDLE_BRC_CERTIFICATION_CHANGE = 'HANDLE_BRC_CERTIFICATION_CHANGE';
export const HANDLE_FCL_CONTAINER_LOAD_CHANGE = 'HANDLE_FCL_CONTAINER_LOAD_CHANGE';
export const HANDLE_LCL_CONTAINER_LOAD_CHANGE = 'HANDLE_LCL_CONTAINER_LOAD_CHANGE';
export const HANDLE_NUT_STYLE_0_CHANGE = 'HANDLE_NUT_STYLE_0_CHANGE';
export const HANDLE_NUT_STYLE_1_CHANGE = 'HANDLE_NUT_STYLE_1_CHANGE';
export const HANDLE_NUT_STYLE_1S_CHANGE = 'HANDLE_NUT_STYLE_1S_CHANGE';
export const HANDLE_NUT_STYLE_2_CHANGE = 'HANDLE_NUT_STYLE_2_CHANGE';
export const HANDLE_NUT_STYLE_3_CHANGE = 'HANDLE_NUT_STYLE_3_CHANGE';
export const HANDLE_NUT_STYLE_4L_CHANGE = 'HANDLE_NUT_STYLE_4L_CHANGE';
export const HANDLE_NUT_STYLE_4S_CHANGE = 'HANDLE_NUT_STYLE_4S_CHANGE';
export const HANDLE_NUT_STYLE_5_CHANGE = 'HANDLE_NUT_STYLE_5_CHANGE';
export const HANDLE_NUT_STYLE_6_CHANGE = 'HANDLE_NUT_STYLE_6_CHANGE';
export const HANDLE_NUT_STYLE_7_CHANGE = 'HANDLE_NUT_STYLE_7_CHANGE';
export const HANDLE_NUT_STYLE_8_CHANGE = 'HANDLE_NUT_STYLE_8_CHANGE';
export const HANDLE_OTHER_NUT_STYLE_CHANGE = 'HANDLE_OTHER_NUT_STYLE_CHANGE';
export const HANDLE_SOUND_KERNEL_RECOVERY_CHANGE = 'HANDLE_SOUND_KERNEL_RECOVERY_CHANGE';
export const HANDLE_UNSOUND_KERNEL_RECOVERY_CHANGE = 'HANDLE_UNSOUND_KERNEL_RECOVERY_CHANGE';
export const HANDLE_TREE_VARIANT_HYBRID_CHANGE = 'HANDLE_TREE_VARIANT_HYBRID_CHANGE';
export const HANDLE_TREE_VARIANT_BEAUMONT_CHANGE = 'HANDLE_TREE_VARIANT_BEAUMONT_CHANGE';
export const HANDLE_TREE_VARIANT_INTEGRIFOLIA_CHANGE = 'HANDLE_TREE_VARIANT_INTEGRIFOLIA_CHANGE';
export const HANDLE_TREE_VARIANT_OTHER_CHANGE = 'HANDLE_TREE_VARIANT_OTHER_CHANGE';
export const HANDLE_HYBRID_TREE_CULTIVARS_CHANGE = 'HANDLE_HYBRID_TREE_CULTIVARS_CHANGE';
export const HANDLE_TREE_INTEG_CULTIVARS_CHANGE = 'HANDLE_TREE_INTEG_CULTIVARS_CHANGE';
export const HANDLE_PREMIUM_NUT_GRADE_CHANGE = 'HANDLE_PREMIUM_NUT_GRADE_CHANGE';
export const HANDLE_COMMERCIAL_NUT_GRADE_CHANGE = 'HANDLE_COMMERCIAL_NUT_GRADE_CHANGE';
export const HANDLE_REJECTED_NUT_GRADE_CHANGE = 'HANDLE_REJECTED_NUT_GRADE_CHANGE';
export const HANDLE_DAP_PAYMENT_OPTION_CHANGE = 'HANDLE_DAP_PAYMENT_OPTION_CHANGE';
export const HANDLE_DAYS_30_PAYMENT_OPTION_CHANGE = 'HANDLE_DAYS_30_PAYMENT_OPTION_CHANGE';
export const HANDLE_DAYS_60_PAYMENT_OPTION_CHANGE = 'HANDLE_DAYS_60_PAYMENT_OPTION_CHANGE';
export const HANDLE_DAYS_90_PAYMENT_OPTION_CHANGE = 'HANDLE_DAYS_90_PAYMENT_OPTION_CHANGE';
export const HANDLE_DAYS_180_PAYMENT_OPTION_CHANGE = 'HANDLE_DAYS_180_PAYMENT_OPTION_CHANGE';
export const HANDLE_CUSTOM_PAYMENT_OPTION_CHANGE = 'HANDLE_CUSTOM_PAYMENT_OPTION_CHANGE';
export const HANDLE_HARVEST_SEASON_CHANGE = 'HANDLE_HARVEST_SEASON_CHANGE';
export const HANDLE_PROCESSED_SEASON_CHANGE = 'HANDLE_PROCESSED_SEASON_CHANGE';
export const HANDLE_BEST_BEFORE_DATE_CHANGE = 'HANDLE_BEST_BEFORE_DATE_CHANGE';
export const HANDLE_PRICE_CHANGE = 'HANDLE_PRICE_CHANGE';
export const HANDLE_QUANTITY_CHANGE = 'HANDLE_QUANTITY_CHANGE';
export const HANDLE_BRC_LEVEL_CHANGE = 'HANDLE_BRC_LEVEL_CHANGE';
export const HANDLE_REGION_CHANGE = 'HANDLE_REGION_CHANGE';
export const HANDLE_COUNTRY_CHANGE = 'HANDLE_COUNTRY_CHANGE';
export const HANDLE_DESTINATION_COUNTRY_CHANGE = 'HANDLE_DESTINATION_COUNTRY_CHANGE';
export const HANDLE_PASTEURIZED_CHANGE = 'HANDLE_PASTEURIZED_CHANGE';
export const HANDLE_HALAAL_CHANGE = 'HANDLE_HALAAL_CHANGE';
export const HANDLE_KOSHER_CHANGE = 'HANDLE_KOSHER_CHANGE';
export const HANDLE_BEST_BEFORE_CHANGE = 'HANDLE_BEST_BEFORE_CHANGE';
export const HANDLE_DNIS_CERTIFICATION_GLOBAL_GAP_CHANGE = 'HANDLE_DNIS_CERTIFICATION_GLOBAL_GAP_CHANGE';
export const HANDLE_DNIS_CERTIFICATION_PPECB_CHANGE = 'HANDLE_DNIS_CERTIFICATION_PPECB_CHANGE';
export const HANDLE_DNIS_CERTIFICATION_GACC_CHANGE = 'HANDLE_DNIS_CERTIFICATION_GACC_CHANGE';
export const HANDLE_DNIS_CERTIFICATION_MRL_CHANGE = 'HANDLE_DNIS_CERTIFICATION_MRL_CHANGE';
export const HANDLE_DNIS_CERTIFICATION_ESG_CHANGE = 'HANDLE_DNIS_CERTIFICATION_ESG_CHANGE';
export const HANDLE_SHIPPING_START_DATE_CHANGE = 'HANDLE_SHIPPING_START_DATE_CHANGE';
export const HANDLE_SHIPPING_END_DATE_CHANGE = 'HANDLE_SHIPPING_END_DATE_CHANGE';
export const HANDLE_RESET_CLICK = 'HANDLE_RESET_CLICK';
export const HANDLE_ORGANIC_CHANGE = 'HANDLE_ORGANIC_CHANGE';
export const HANDLE_NUT_STYLE_RESET_CLICK = 'HANDLE_NUT_STYLE_RESET_CLICK';
export const HANDLE_NUT_SIZE_RESET_CLICK = 'HANDLE_NUT_SIZE_RESET_CLICK';
export const HANDLE_NUT_SIZE_20_PLUS_CHANGE = 'HANDLE_NUT_SIZE_20_PLUS_CHANGE';
export const HANDLE_NUT_SIZE_18_PLUS_CHANGE = 'HANDLE_NUT_SIZE_18_PLUS_CHANGE';
export const HANDLE_NUT_SIZE_22_PLUS_CHANGE = 'HANDLE_NUT_SIZE_22_PLUS_CHANGE';
export const HANDLE_NUT_SIZE_25_PLUS_CHANGE = 'HANDLE_NUT_SIZE_25_PLUS_CHANGE';
export const HANDLE_NUT_SIZE_20_22_CHANGE = 'HANDLE_NUT_SIZE_20_22_CHANGE';
export const HANDLE_NUT_SIZE_22_25_CHANGE = 'HANDLE_NUT_SIZE_22_25_CHANGE';
export const HANDLE_NUT_SIZE_18_20_CHANGE = 'HANDLE_NUT_SIZE_18_20_CHANGE';
export const HANDLE_NUT_SIZE_LESS_THAN_18_CHANGE = 'HANDLE_NUT_SIZE_LESS_THAN_18_CHANGE';
export const HANDLE_OTHER_NUT_SIZE_CHANGE = 'HANDLE_OTHER_NUT_SIZE_CHANGE';
export const HANDLE_NUT_GRADE_RESET_CLICK = 'HANDLE_NUT_GRADE_RESET_CLICK';
export const HANDLE_PAYMENT_OPTION_RESET_CLICK = 'HANDLE_PAYMENT_OPTION_RESET_CLICK';
export const HANDLE_CERTIFICATIONS_RESET_CLICK = 'HANDLE_CERTIFICATIONS_RESET_CLICK';
export const HANDLE_TREE_VARIANT_RESET_CLICK = 'HANDLE_TREE_VARIANT_RESET_CLICK';
export const HANDLE_DNIS_CERTIFICATIONS_RESET_CLICK = 'HANDLE_DNIS_CERTIFICATIONS_RESET_CLICK';

export const SORT_COLUMN_BY_DATE = 'date';
export const SORT_COLUMN_BY_PRICE = 'offer_price';
export const SORT_ORDER_ASC = 'up';
export const SORT_ORDER_DESC = 'down';
export const FIRST_PAGE = 1;
export const TOTAL_PAGE_ONE = 1;
export const LISTINGS_COUNT_ZERO = 0;
export const LISTINGS_PER_PAGE = 20;

export const OFFERS = 'offers';
export const OFFER = 'offer';
export const ORDERS = 'orders';
export const ORDER = 'order';
export const CONTRACTS = 'contracts';
export const UNPUBLISHED = 'unpublished';
export const PUBLISHED = 'published';
export const ACCEPTED = 'accepted';
export const APPLIED = 'applied';
export const CONCLUDED = 'concluded';
export const CONCLUDED_CONTRACTS = 'concluded_contracts';
export const COMPLETED = 'completed';
export const ONBOARDED = 'onboarded';
export const INVITED = 'invited';
export const ARCHIVED = 'archived';
export const DISSOLVED = 'dissolved';
export const IN_PROGRESS = 'in_progress';

export const THIS_WEEK = 'this_week';
export const THIS_MONTH = 'this_month';
export const PREVIOUS_YEAR = 'previous_year';
export const THIS_YEAR = 'this_year';
export const NEXT_YEAR = 'next_year';
export const UNSPECIFIED = 'unspecified';
export const YOUR_DATE = 'your_date';

export const formattedProductStatus = {
  [CONCLUDED]: t('admin.listings_table.concluded'),
  [UNPUBLISHED]: t('admin.listings_table.draft'),
  [PUBLISHED]: t('admin.listings_table.active'),
  [ACCEPTED]: t('admin.listings_table.accepted'),
  [IN_PROGRESS]: t('admin.listings_table.in_progress_status'),
  [COMPLETED]: t('admin.listings_table.completed_status'),
};

export const getContractStatusLabel = {
  [CONCLUDED]: t('admin.listings_table.concluded'),
  [IN_PROGRESS]: t('admin.listings_table.in_progress_status'),
  [COMPLETED]: t('admin.listings_table.completed_status'),
  [DISSOLVED]: t('admin.listings_table.dissolved_status'),
};

export const ADMIN_LISTINGS_PER_PAGE = 10;

export const filterOptions = [
  {
    label: t('offer_listing.filter.time_desc'),
    column: SORT_COLUMN_BY_DATE,
    direction: SORT_ORDER_DESC,
  },
  {
    label: t('offer_listing.filter.time_asc'),
    column: SORT_COLUMN_BY_DATE,
    direction: SORT_ORDER_ASC,
  },
  {
    label: t('offer_listing.filter.price_asc'),
    column: SORT_COLUMN_BY_PRICE,
    direction: SORT_ORDER_ASC,
  },
  {
    label: t('offer_listing.filter.price_desc'),
    column: SORT_COLUMN_BY_PRICE,
    direction: SORT_ORDER_DESC,
  },
];

export const listingTypeOptions = [
  {
    label: t('offer_listing.filter.listing_type.options.offers'),
    name: 'offers',
  },
  {
    label: t('offer_listing.filter.listing_type.options.orders'),
    name: 'orders',
  },
  {
    label: t('offer_listing.filter.listing_type.options.concluded'),
    name: 'concluded_contracts',
  },
];

export const listingDateRangeOptions = [
  {
    label: t('offer_listing.filter.shipping_date_range.options.week'),
    name: THIS_WEEK,
  },
  {
    label: t('offer_listing.filter.shipping_date_range.options.month'),
    name: THIS_MONTH,
  },
  {
    label: t('offer_listing.filter.shipping_date_range.options.previous_season', {
      previousYear: currentYear - 1,
    }),
    name: PREVIOUS_YEAR,
  },
  {
    label: t('offer_listing.filter.shipping_date_range.options.current_season', { currentYear }),
    name: THIS_YEAR,
  },
  {
    label: t('offer_listing.filter.shipping_date_range.options.next_season', {
      nextYear: currentYear + 1,
    }),
    name: NEXT_YEAR,
  },
  {
    label: t('offer_listing.filter.shipping_date_range.options.unspecified'),
    name: UNSPECIFIED,
  },
  {
    label: t('offer_listing.filter.shipping_date_range.options.pick_your_date'),
    name: YOUR_DATE,
  },
];

export const containerLoadOptions = [
  {
    label: t('offer_listing.filter.container_load.options.fcl'),
    name: 'fcl',
  },
  {
    label: t('offer_listing.filter.container_load.options.lcl'),
    name: 'lcl',
  },
];

export const nutStyleOptions = [
  {
    label: t('shared.product.nut_style.style_0'),
    name: STYLE_0,
  },
  {
    label: t('shared.product.nut_style.style_1'),
    name: STYLE_1,
  },
  {
    label: t('shared.product.nut_style.style_1_s'),
    name: STYLE_1_S,
  },
  {
    label: t('shared.product.nut_style.style_2'),
    name: STYLE_2,
  },
  {
    label: t('shared.product.nut_style.style_3'),
    name: STYLE_3,
  },  {
    label: t('shared.product.nut_style.style_4_l'),
    name: STYLE_4_L,
  },
  {
    label: t('shared.product.nut_style.style_4_s'),
    name: STYLE_4_S,
  },
  {
    label: t('shared.product.nut_style.style_5'),
    name: STYLE_5,
  },
  {
    label: t('shared.product.nut_style.style_6'),
    name: STYLE_6,
  },
  {
    label: t('shared.product.nut_style.style_7'),
    name: STYLE_7,
  },
  {
    label: t('shared.product.nut_style.style_8'),
    name: STYLE_8,
  },
  {
    label: t('shared.product.nut_style.other'),
    name: STYLE_CUSTOM,
  },
];

export const sizeOptions = [
  {
    label: t('offer_listing.filter.nut_size.options.size_18p'),
    name: SIZE_18P,
  },
  {
    label: t('offer_listing.filter.nut_size.options.size_18_20'),
    name: SIZE_18_20,
  },
  {
    label: t('offer_listing.filter.nut_size.options.size_20_22'),
    name: SIZE_20_22,
  },
  {
    label: t('offer_listing.filter.nut_size.options.size_22_25'),
    name: SIZE_22_25,
  },
  {
    label: t('offer_listing.filter.nut_size.options.size_25'),
    name: SIZE_25,
  },
  {
    label: t('offer_listing.filter.nut_size.options.size_22'),
    name: SIZE_22,
  },
  {
    label: t('offer_listing.filter.nut_size.options.size_20'),
    name: SIZE_20,
  },
  {
    label: t('offer_listing.filter.nut_size.options.size_18'),
    name: SIZE_18,
  },
  {
    label: t('offer_listing.filter.nut_size.options.other'),
    name: SIZE_CUSTOM,
  },
];

export const soundKernelRecoveryOptions = [
  {
    label: '<25%',
    name: SKR_0,
  },
  {
    label: '>25%',
    name: SKR_25,
  },
  {
    label: '>26%',
    name: SKR_26,
  },
  {
    label: '>27%',
    name: SKR_27,
  },
  {
    label: '>28%',
    name: SKR_28,
  },
  {
    label: '>29%',
    name: SKR_29,
  },
  {
    label: '>30%',
    name: SKR_30,
  },
  {
    label: '>31%',
    name: SKR_31,
  },
  {
    label: '>32%',
    name: SKR_32,
  },
  {
    label: '>33%',
    name: SKR_33,
  },
  {
    label: '>34%',
    name: SKR_34,
  },
  {
    label: '>35%',
    name: SKR_35,
  },
];

export const unsoundKernelRecoveryOptions = [
  {
    label: '<0.5%',
    name: USKR_05,
  },
  {
    label: '<1%',
    name: USKR_1,
  },
  {
    label: '<1.5%',
    name: USKR_15,
  },
  {
    label: '<2%',
    name: USKR_2,
  },
  {
    label: '<2.5%',
    name: USKR_25,
  },
  {
    label: '<3%',
    name: USKR_3,
  },
  {
    label: '<3.5%',
    name: USKR_35,
  },
  {
    label: '<4%',
    name: USKR_4,
  },
  {
    label: '<4.5%',
    name: USKR_45,
  },
  {
    label: '<5%',
    name: USKR_5,
  },
  {
    label: '<5.5%',
    name: USKR_55,
  },
  {
    label: '<6%',
    name: USKR_6,
  },
  {
    label: '<6.5%',
    name: USKR_65,
  },
  {
    label: '<7%',
    name: USKR_7,
  },
  {
    label: '>7%',
    name: USKR_7P,
  },
];

export const nutGradeOptions = [
  {
    label: t('offer_listing.filter.nut_grade.options.premium'),
    name: PREMIUM,
  },
  {
    label: t('offer_listing.filter.nut_grade.options.commercial'),
    name: COMMERCIAL,
  },
  {
    label: t('offer_listing.filter.nut_grade.options.rejected'),
    name: REJECTED,
  },
];

export const paymentOptions = [
  {
    label: t('offer_listing.filter.payment.options.dap'),
    name: DAP,
  },
  {
    label: t('offer_listing.filter.payment.options.30_days'),
    name: DAYS_30,
  },
  {
    label: t('offer_listing.filter.payment.options.60_days'),
    name: DAYS_60,
  },
  {
    label: t('offer_listing.filter.payment.options.90_days'),
    name: DAYS_90,
  },
  {
    label: t('offer_listing.filter.payment.options.180_days'),
    name: DAYS_180,
  },
  {
    label: t('offer_listing.filter.payment.options.custom_payment'),
    name: CUSTOM_PAYMENT_OPTION,
  },
];

export const certificationOptions = [
  {
    label: t('offer_listing.filter.certifications.options.haccp'),
    name: 'haccp',
  },
  {
    label: t('offer_listing.filter.certifications.options.fssc'),
    name: 'fssc',
  },
  {
    label: t('offer_listing.filter.certifications.options.brc'),
    name: 'brc',
  },
];

export const brcLevelOptions = [
  {
    label: t('offer_listing.filter.brc_levels.options.aa_grade'),
    name: BRC_AA_GRADE,
  },
  {
    label: t('offer_listing.filter.brc_levels.options.a_grade'),
    name: BRC_A_GRADE,
  },
  {
    label: t('offer_listing.filter.brc_levels.options.b_grade'),
    name: BRC_B_GRADE,
  },
  {
    label: t('offer_listing.filter.brc_levels.options.c_grade'),
    name: BRC_C_GRADE,
  },
  {
    label: t('offer_listing.filter.brc_levels.options.d_grade'),
    name: BRC_D_GRADE,
  },
];

export const unverifiedProfileCTA = {
  heading: t('offer_listing.marketplace.overlay_cta.heading'),
  subHeadings: [
    t('offer_listing.marketplace.overlay_cta.subheading1'),
    t('offer_listing.marketplace.overlay_cta.subheading2'),
  ],
  buttonText: t('offer_listing.marketplace.overlay_cta.button_text'),
};

export const unverifiedProfileCTAForSubscriptionMember = {
  heading: t('offer_listing.marketplace.overlay_cta.subscription_member.heading'),
  subHeadings: [
    t('offer_listing.marketplace.overlay_cta.subscription_member.subheading1'),
    t('offer_listing.marketplace.overlay_cta.subscription_member.subheading2'),
  ],
  buttonText: t('offer_listing.marketplace.overlay_cta.subscription_member.button_text'),
};
