import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  listingsPriceChartDataLines,
  getListingsPriceChartOptions,
  chartAxis,
  chartMargin,
} from 'utils/constants/Charts';
import { LineChart } from 'components/BaseComponents/Charts';
import { formatPrice } from 'utils/helper/Charts';
import './styles.scss';

const LineChartComponent = ({
  priceTitle,
  price,
  volumeTitle,
  volume,
  aggregatePriceGraph,
  className,
  currency,
}) => (
  <div className={classNames('line-chart-component', className)}>
    <div className="listings-line-chart-component__chart">
      <div className="listings-line-chart-component__heading">
        <div>
          <h4 className="listings-line-chart-component__chart--title">{priceTitle}</h4>
          <h3 className="listings-line-chart-component__chart--subtitle">{price}</h3>
        </div>
        <div>
          <h4 className="listings-line-chart-component__chart--title">{volumeTitle}</h4>
          <h3 className="listings-line-chart-component__chart--subtitle">{volume}</h3>
        </div>
      </div>
      <LineChart
        axis={chartAxis}
        data={aggregatePriceGraph}
        dataLines={listingsPriceChartDataLines}
        margin={chartMargin}
        chartLineOptions={getListingsPriceChartOptions(currency)}
        formatter={formatPrice}
      />
    </div>
  </div>
);

LineChartComponent.defaultProps = {
  className: null,
  price: null,
  volume: null,
  isGraphUpdated: false,
  currency: 'USD',
};

LineChartComponent.propTypes = {
  priceTitle: PropTypes.string.isRequired,
  price: PropTypes.string,
  volumeTitle: PropTypes.string.isRequired,
  volume: PropTypes.string,
  aggregatePriceGraph: PropTypes.arrayOf(
    PropTypes.shape({
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ).isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  isGraphUpdated: PropTypes.bool,
  currency: PropTypes.string,
};

const graphUpdated = (_, newProps) => {
  const { isGraphUpdated } = newProps;
  return !isGraphUpdated;
};
const MemoizedLineChart = memo(LineChartComponent, graphUpdated);

export default MemoizedLineChart;
