import { t } from "i18next";

export const PRICE_CHART_TYPE = "price chart";
export const VOLUME_CHART_TYPE = "volume chart";
export const PRICE_CHART_DATALINE_KEY = "avg";
export const VOLUME_CHART_DATALINE_KEY = "avg";
export const PRICE_CHART_TOTAL_AVG_DATALINE_KEY = "totalAvg";
export const PRICE_CHART_MY_AVG_DATALINE_KEY = "myAvg";

export const listingsPriceChartDataLines = [
  PRICE_CHART_MY_AVG_DATALINE_KEY,
  PRICE_CHART_DATALINE_KEY,
  PRICE_CHART_TOTAL_AVG_DATALINE_KEY,
];
export const getListingsPriceChartOptions = (currency) => ({
  [PRICE_CHART_DATALINE_KEY]: {
    name: `${t("aggregate_sales.price_chart.legend_text")} (${currency})`,
    color: "green",
    legendType: "rect",
  },
  [PRICE_CHART_TOTAL_AVG_DATALINE_KEY]: {
    name: null,
    color: "secondary",
    dot: false,
    activeDot: false,
    legendType: "none",
  },
  [PRICE_CHART_MY_AVG_DATALINE_KEY]: {
    name: `${t("aggregate_sales.price_chart.my_price_legend_text")} (${currency})`,
    color: "red-500",
    legendType: "rect",
  },
});

export const chartAxis = {
  x: true,
  y: true,
};

export const chartMargin = {
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
};
