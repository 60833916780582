import React, { useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useGetCompanyQuery } from 'api/companies';
import { useGetProductsQuery } from 'api/products/index';
import { formatTonsVolume, priceWithCurrency } from 'utils/helper/Charts';
import {
  HANDLE_PAGE_CHANGE,
  HANDLE_SORT_CHANGE,
  LISTINGS_COUNT_ZERO,
  LISTINGS_PER_PAGE,
  ORDERS,
  TOTAL_PAGE_ONE,
  unverifiedProfileCTA,
  unverifiedProfileCTAForSubscriptionMember,
} from 'utils/constants/offerListings';
import { hasSubscriptionMemberRole, isVerified } from 'utils/helper/UserRoleValidation';
import LoadingSpinner from 'components/BaseComponents/LoadingSpinner';
import Pagination from 'components/BaseComponents/Pagination';
import DriedNutinShellAggregateDataFilter from 'components/Shared/BiddingProcess/DriedNutInShellAggregateDataFilter';
import KernelAggregateDataFilter from 'components/Shared/BiddingProcess/KernelAggregateDataFilter';
import ListingCardContainer from 'components/Shared/BiddingProcess/ListingCardContainer';
import OverlayCTA from 'components/Shared/OverlayCTA';
import EmptyContentPlaceholder from 'components/Shared/EmptyContentPlaceholder';
import Button from 'components/BaseComponents/Button';
import { DRIED_NUT_IN_SHELL_PRODUCT, KERNEL_PRODUCT } from 'utils/constants/product';
import filterReducer, { initialFilterState } from './filterReducer';
import SortFilter from '../AggregateDataSort';
import LineChartComponent from '../LineChartComponent';

const ListingsContainer = ({ roles, companyId, nutsTab, setNutsTab }) => {
  const { t } = useTranslation();
  const [filterState, dispatch] = useReducer(filterReducer, initialFilterState);
  const {
    myListings,
    listingType,
    listingDateRange,
    harvestSeason: { value: harvestSeason },
    processedSeason: { value: processedSeason },
    shippingStartDate,
    shippingEndDate,
    bestBeforeDate,
    minPricePerKg,
    maxPricePerKg,
    minQuantity,
    maxQuantity,
    haccp,
    fssc,
    brc,
    fcl,
    lcl,
    style0,
    style1,
    style1s,
    style2,
    style3,
    style4l,
    style4s,
    style5,
    style6,
    style7,
    style8,
    otherNutStyle,
    lessThan18mm,
    size1820mm,
    size2022mm,
    size2225mm,
    size25mmPlus,
    size22mmPlus,
    size20mmPlus,
    size18mmPlus,
    otherSize,
    brcLevel,
    region: { value: region },
    country,
    destinationCountry,
    soundKernelRecovery: { name: soundKernelRecovery },
    unsoundKernelRecovery: { name: unsoundKernelRecovery },
    beaumont,
    hybrid,
    treeHybridCultivar,
    integrifolia,
    treeIntegCultivar,
    otherTreeVariant,
    premium,
    commercial,
    rejected,
    dap,
    days30,
    days60,
    days90,
    days180,
    customPaymentOption,
    ppecb,
    globalGap,
    gacc,
    mrl,
    esg,
    halaal,
    kosher,
    organic,
    pasteurized,
    sort: { label, column, direction },
    withGraphData,
    pageNumber: selectedPageNumber,
  } = filterState;
  const selectedSort = { label, column, direction };
  const { data: companyDetails = {} } = useGetCompanyQuery({ id: companyId }, { skip: !companyId });

  const {
    data: listings = {},
    isFetching,
    refetch,
  } = useGetProductsQuery({
    column,
    direction,
    with_price_graph_data: withGraphData,
    with_filter_stats: true,
    for_listings: true,
    page: selectedPageNumber,
    per_page: LISTINGS_PER_PAGE,
    my_listings: myListings,
    listing_type: listingType,
    product_type: nutsTab,
    time_period: listingDateRange,
    harvest_season: harvestSeason,
    processed_season: processedSeason,
    best_before_date: bestBeforeDate,
    shipping_start_date: shippingStartDate,
    shipping_end_date: shippingEndDate,
    min_price_per_kg: minPricePerKg,
    max_price_per_kg: maxPricePerKg,
    min_quantity: minQuantity,
    max_quantity: maxQuantity,
    brc_level: brcLevel,
    region,
    country,
    destination_country: destinationCountry,
    sound_kernel_recovery: soundKernelRecovery,
    unsound_kernel_recovery: unsoundKernelRecovery,
    hybrid,
    tree_hybrid_cultivar: treeHybridCultivar?.map((option) => option.value),
    beaumont,
    integrifolia,
    tree_integ_cultivar: treeIntegCultivar?.map((option) => option.value),
    other_tree_variant: otherTreeVariant,
    premium,
    commercial,
    rejected,
    dap,
    days_30: days30,
    days_60: days60,
    days_90: days90,
    days_180: days180,
    haccp,
    fssc,
    brc,
    fcl,
    lcl,
    style_0: style0,
    style_1: style1,
    style_1s: style1s,
    style_2: style2,
    style_3: style3,
    style_4l: style4l,
    style_4s: style4s,
    style_5: style5,
    style_6: style6,
    style_7: style7,
    style_8: style8,
    other_nut_style: otherNutStyle,
    less_than_18mm: lessThan18mm,
    size_18_20mm: size1820mm,
    size_20_22mm: size2022mm,
    size_22_25mm: size2225mm,
    size_25mm_plus: size25mmPlus,
    size_22mm_plus: size22mmPlus,
    size_20mm_plus: size20mmPlus,
    size_18mm_plus: size18mmPlus,
    other_size: otherSize,
    custom_payment_option: customPaymentOption,
    pasteurized,
    global_gap: globalGap,
    ppecb,
    gacc,
    mrl,
    esg,
    halaal,
    kosher,
    organic,
  });

  useEffect(() => {
    refetch();
  }, [nutsTab]);

  const {
    heading: ctaHeading,
    subHeadings: ctaSubHeadings,
    buttonText: ctaButtonText,
  } = hasSubscriptionMemberRole({ roles }) ? unverifiedProfileCTAForSubscriptionMember : unverifiedProfileCTA;
  const {
    collection: {
      collection: listingProducts = [],
      aggregatedPriceGraph: {
        graphData: aggregatePriceGraph = [{}],
        overallPeriodAverage = '0.00',
        totalTons = '0.000',
      } = {},
      filterStats = {},
    } = {},
    pagination: {
      totalPages = TOTAL_PAGE_ONE,
      totalCount: totalListings = LISTINGS_COUNT_ZERO,
    } = {},
  } = listings;
  const isUserVerified = isVerified(companyDetails);
  const isListingExists = listingProducts.length > 0;
  const currency = listingType === ORDERS ? t('shared.price.dap_cfr') : t('shared.price.exw')

  return (
    <div className="flex flex-col margin-x-auto">
      <div className="tab-buttons">
        <Button
          type={nutsTab === DRIED_NUT_IN_SHELL_PRODUCT ? 'success' : 'default'}
          className="tab-buttons__dried_nut_in_shell"
          label="Dried Nut in Shell"
          onClick={() => {
            setNutsTab(DRIED_NUT_IN_SHELL_PRODUCT);
          }}
        />
        <Button
          type={nutsTab === KERNEL_PRODUCT ? 'success' : 'default'}
          className="tab-buttons__kernel"
          label="Kernel"
          onClick={() => {
            setNutsTab(KERNEL_PRODUCT);
          }}
        />
      </div>
      <div className="offer-listing__marketplace flex justify-between margin-x-auto">
        <div className="offer-listing__marketplace--filter-container">
          {nutsTab === DRIED_NUT_IN_SHELL_PRODUCT && (
            <DriedNutinShellAggregateDataFilter
              filterState={filterState}
              disabled={!isUserVerified}
              dispatch={dispatch}
              priceRange={filterStats?.price}
              volumeRange={filterStats?.volume}
            />
          )}
          {nutsTab === KERNEL_PRODUCT && (
            <KernelAggregateDataFilter
              filterState={filterState}
              disabled={!isUserVerified}
              dispatch={dispatch}
              priceRange={filterStats?.price}
              volumeRange={filterStats?.volume}
            />
          )}
        </div>
        <div className="offer-listing__marketplace--items-container">
          <LineChartComponent
            className="offer-listing__price-chart"
            priceTitle={t('offer_listing.chart.price_title')}
            price={priceWithCurrency(overallPeriodAverage, currency)}
            volumeTitle={t(`offer_listing.chart.volume_title.${listingType}`)}
            volume={formatTonsVolume(totalTons)}
            aggregatePriceGraph={aggregatePriceGraph}
            isGraphUpdated={withGraphData}
            currency={currency}
          />
          <SortFilter
            nutsTab={nutsTab}
            listingType={listingType}
            totalListings={Number(totalListings)}
            selectedSort={selectedSort}
            onSort={(value) => dispatch({ type: HANDLE_SORT_CHANGE, payload: value })}
          />
          {!isFetching && isListingExists && (
            <div className="flex flex-col gap-8px">
              <ListingCardContainer listings={listingProducts} isUserVerified={isUserVerified} currency={currency} />
            </div>
          )}
          {isFetching && <LoadingSpinner className="loading-spinner__relative" />}
          {isListingExists && !isUserVerified && (
            <OverlayCTA
              className="offer-listing__marketplace--overlay-cta"
              heading={ctaHeading}
              subHeadings={ctaSubHeadings}
              ctaButtonText={ctaButtonText}
              ctaButtonLink="/profile"
            />
          )}
          {!isListingExists && !isFetching && (
            <EmptyContentPlaceholder
              heading={t('shared.bidding_process.empty_content_placeholder.heading')}
              description={t('shared.bidding_process.empty_content_placeholder.description')}
            />
          )}
          {isUserVerified && (
            <Pagination
              currentPage={selectedPageNumber}
              totalPages={Number(totalPages)}
              onPaginationClick={(pageNumber) =>
                dispatch({ type: HANDLE_PAGE_CHANGE, payload: pageNumber })
              }
              firstText={t('admin_user_table.first_page')}
              lastText={t('admin_user_table.last_page')}
              nextText={t('admin_user_table.next_page')}
              previousText={t('admin_user_table.previous_page')}
              className="offer-listing__marketplace--items-container-pagination margin-t-32px"
            />
          )}
        </div>
      </div>
    </div>
  );
};

ListingsContainer.defaultProps = {
  companyId: null,
  roles: null,
  nutsTab: null,
  setNutsTab: null,
};

ListingsContainer.propTypes = {
  companyId: PropTypes.number,
  roles: PropTypes.arrayOf(PropTypes.string),
  nutsTab: PropTypes.string,
  setNutsTab: PropTypes.func,
};

export default ListingsContainer;
