import React, { useContext, useState } from 'react';
import { t } from 'i18next';
import classNames from 'classnames';
import { formatPrice } from 'utils/helper/Charts';
import Modal from 'components/BaseComponents/Modal';
import Tooltip from 'components/BaseComponents/Tooltip';
import ListingDetailsContext from 'components/BiddingProcess/ListingDetails/ListingDetailsContext';
import AcceptOfferModal from 'components/BiddingProcess/ListingDetails/ListingInformationBody/ActionPanel/AcceptOfferOrderModal';
import AdjustOfferPriceModal from 'components/BiddingProcess/ListingDetails/ListingInformationBody/ActionPanel/AdjustOfferOrderPriceModal';
import { hasNoRoles } from 'utils/helper/UserRoleValidation';
import './styles.scss';

const OfferedPrice = () => {
  const {
    isUserAdmin,
    listingInformation,
    productInformation,
    isListingInContract,
    isListingConcluded,
    isUserSeller,
    isUserSubscriptionMember,
    currentUserInformation,
    isOfferListing,
    isListingCreator,
    isUserCompanyOwner,
  } = useContext(ListingDetailsContext);

  const [isAcceptOfferModalVisible, setIsAcceptOfferModalVisible] = useState(false);
  const [isAdjustOfferModalVisible, setIsAdjustOfferModalVisible] = useState(false);

  const onAcceptOfferModalOpen = () => setIsAcceptOfferModalVisible(true);
  const onAcceptOfferModalClose = () => setIsAcceptOfferModalVisible(false);

  const onAdjustOfferModalOpen = () => setIsAdjustOfferModalVisible(true);
  const onAdjustOfferModalClose = () => setIsAdjustOfferModalVisible(false);

  const titleMap = {
    true: t('bidding_process.price'),
    [isUserAdmin]: t('bidding_process.asking_price'),
    default: t('bidding_process.offered_price'),
  };
  const cannotAct = isUserSubscriptionMember || hasNoRoles(currentUserInformation);
  const modalTitle = titleMap[isListingConcluded] || titleMap[isUserAdmin] || titleMap.default;
  const offerPrice = `${t(`shared.price.${productInformation.priceType}`)} ${formatPrice(productInformation.offerPrice)}`;
  const canViewAdjustOfferPriceButton = (isListingCreator || isUserCompanyOwner) && !isListingInContract && !isUserAdmin;
  const isNonCreatorSeller = isUserSeller && !isListingInContract && !isUserAdmin;
  const canViewAcceptOfferOrderButton = !isListingInContract && !isUserAdmin && !cannotAct;
  const hasNoActionButton = isListingConcluded || isUserAdmin || isListingInContract;

  const renderActionButton = () => {
    if (canViewAdjustOfferPriceButton) {
      return (
        <button
          type="button"
          onClick={onAdjustOfferModalOpen}
          className="offered-price-body__adjust-offer-button"
        >
          {t('bidding_process.adjust_offer_price')}
        </button>
      );
    }

    if (isNonCreatorSeller) {
      return (
        <Tooltip
          content={t('bidding_process.you_are_registered_as_a_seller')}
          showArrow={true}
          type="midnight-blue"
          size="small"
        >
          <button
            type="button"
            className="offered-price-body__accept-order-button
              offered-price-body__accept-order-button--disabled"
            disabled="true"
          >
            {t('bidding_process.accept_offer')}
          </button>
        </Tooltip>
      );
    }

    if (canViewAcceptOfferOrderButton) {
      return (
        <button
          type="button"
          className="offered-price-body__accept-order-button"
          onClick={onAcceptOfferModalOpen}
        >
          {isOfferListing ? t('bidding_process.accept_offer') : t('bidding_process.accept_order')}
        </button>
      );
    }

    return null;
  };

  return (
    <div className="offered-price">
      <div className="offered-price-title">{modalTitle}</div>
      <div className="offered-price-body">
        <div
          className={classNames('offered-price-body__data', {
            'offered-price-body__data--concluded': hasNoActionButton,
          })}
        >
          <span className="offered-price-body__data--currency">{offerPrice}</span>
          <span className="offered-price-body__data--unit">/{t('bidding_process.per_kg')}</span>
        </div>

        {renderActionButton()}
      </div>

      <Modal
        isVisible={isAcceptOfferModalVisible}
        onOutsideClick={onAcceptOfferModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <AcceptOfferModal onModalClose={onAcceptOfferModalClose} />
        </Modal.Content>
      </Modal>

      <Modal
        isVisible={isAdjustOfferModalVisible}
        onOutsideClick={onAdjustOfferModalClose}
        className="progress-card-modal"
      >
        <Modal.Content>
          <AdjustOfferPriceModal
            highestBidPrice={listingInformation?.highestBidPrice}
            onModalClose={onAdjustOfferModalClose}
            offerVolume={productInformation.offerVolume}
          />
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default OfferedPrice;
