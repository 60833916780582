import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  OFFERS,
  HANDLE_BEST_BEFORE_CHANGE,
  HANDLE_SHIPPING_START_DATE_CHANGE,
  HANDLE_SHIPPING_END_DATE_CHANGE,
  HANDLE_BRC_LEVEL_CHANGE,
  HANDLE_HACCP_CERTIFICATION_CHANGE,
  HANDLE_FSSC_CERTIFICATION_CHANGE,
  HANDLE_BRC_CERTIFICATION_CHANGE,
  HANDLE_FCL_CONTAINER_LOAD_CHANGE,
  HANDLE_LCL_CONTAINER_LOAD_CHANGE,
  HANDLE_NUT_STYLE_0_CHANGE,
  HANDLE_NUT_STYLE_1_CHANGE,
  HANDLE_NUT_STYLE_1S_CHANGE,
  HANDLE_NUT_STYLE_2_CHANGE,
  HANDLE_NUT_STYLE_3_CHANGE,
  HANDLE_NUT_STYLE_4L_CHANGE,
  HANDLE_NUT_STYLE_4S_CHANGE,
  HANDLE_NUT_STYLE_5_CHANGE,
  HANDLE_NUT_STYLE_6_CHANGE,
  HANDLE_NUT_STYLE_7_CHANGE,
  HANDLE_NUT_STYLE_8_CHANGE,
  HANDLE_OTHER_NUT_STYLE_CHANGE,
  HANDLE_COUNTRY_CHANGE,
  HANDLE_DESTINATION_COUNTRY_CHANGE,
  HANDLE_PASTEURIZED_CHANGE,
  HANDLE_HARVEST_SEASON_CHANGE,
  HANDLE_LISTING_DATE_CHANGE,
  HANDLE_LISTING_TYPE_CHANGE,
  HANDLE_MY_LISTINGS_CHANGE,
  HANDLE_PREMIUM_NUT_GRADE_CHANGE,
  HANDLE_COMMERCIAL_NUT_GRADE_CHANGE,
  HANDLE_REJECTED_NUT_GRADE_CHANGE,
  HANDLE_DAP_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_30_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_60_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_90_PAYMENT_OPTION_CHANGE,
  HANDLE_DAYS_180_PAYMENT_OPTION_CHANGE,
  HANDLE_CUSTOM_PAYMENT_OPTION_CHANGE,
  HANDLE_PRICE_CHANGE,
  HANDLE_PROCESSED_SEASON_CHANGE,
  HANDLE_QUANTITY_CHANGE,
  HANDLE_REGION_CHANGE,
  HANDLE_HALAAL_CHANGE,
  HANDLE_KOSHER_CHANGE,
  HANDLE_ORGANIC_CHANGE,
  HANDLE_RESET_CLICK,
  HANDLE_NUT_GRADE_RESET_CLICK,
  HANDLE_NUT_STYLE_RESET_CLICK,
  HANDLE_PAYMENT_OPTION_RESET_CLICK,
  HANDLE_CERTIFICATIONS_RESET_CLICK,
  brcLevelOptions,
  listingDateRangeOptions,
  listingTypeOptions,
  THIS_YEAR,
  YOUR_DATE,
  HANDLE_DNIS_CERTIFICATION_GACC_CHANGE,
} from 'utils/constants/offerListings';
import {
  KERNEL_PRODUCT,
  regions,
  seasons,
  PREMIUM,
  COMMERCIAL,
  REJECTED,
  DAP,
  DAYS_30,
  DAYS_60,
  DAYS_90,
  DAYS_180,
  CUSTOM_PAYMENT_OPTION,
  HALAAL,
  KOSHER,
  ORGANIC,
  STYLE_0,
  STYLE_1,
  STYLE_1_S,
  STYLE_2,
  STYLE_3,
  STYLE_4_L,
  STYLE_4_S,
  STYLE_5,
  STYLE_6,
  STYLE_7,
  STYLE_8,
  STYLE_CUSTOM,
  PASTEURIZED,
  GACC,
} from 'utils/constants/product';
import Button from 'components/BaseComponents/Button';
import RangeSlider from 'components/BaseComponents/RangeSlider';
import { MY_LISTINGS } from 'utils/tagTypes';
import RadioFilter from '../AggregateDataFilter/RadioFilter';
import CheckBoxFilter from '../AggregateDataFilter/CheckBoxFilter';
import SelectFilter from '../AggregateDataFilter/SelectFilter';
import BestBeforeFilter from '../AggregateDataFilter/BestBeforeFilter';
import CountryFilter from '../AggregateDataFilter/CountryFilter';
import DateRangeFilter from '../AggregateDataFilter/DateRangeFilter';
import './styles.scss';

const KernelAggregateDataFilter = ({ filterState, dispatch, priceRange, volumeRange, disabled }) => {
  const { t } = useTranslation();
  const {
    myListings,
    listingType,
    listingDateRange,
    style0,
    style1,
    style1s,
    style2,
    style3,
    style4l,
    style4s,
    style5,
    style6,
    style7,
    style8,
    otherNutStyle,
    fcl,
    lcl,
    premium,
    commercial,
    rejected,
    dap,
    days30,
    days60,
    days90,
    days180,
    customPaymentOption,
    harvestSeason,
    processedSeason,
    bestBeforeDate,
    haccp,
    fssc,
    brc,
    shippingStartDate,
    shippingEndDate,
    brcLevel,
    region,
    country,
    destinationCountry,
    halaal,
    kosher,
    organic,
    pasteurized,
    gacc,
  } = filterState;
  const { min: minPriceBound, max: maxPriceBound } = priceRange;
  const { min: minVolumeBound, max: maxVolumeBound } = volumeRange;
  const [isPickYourDateActive, setIsPickYourDateActive] = useState(false);
  const [dateRange, setDateRange] = useState(listingDateRange);

  const [brcLevelsFilterVisible, setBrcLevelsFilterVisible] = useState(brc === true);

  const handlePriceInputChange = (price) => dispatch({ type: HANDLE_PRICE_CHANGE, payload: price });
  const handleQuantityInputChange = (quantity) =>
    dispatch({ type: HANDLE_QUANTITY_CHANGE, payload: quantity });

  return (
    <div className="aggregate-data-filter">
      <div className="filters-green border-filter">
        <CheckBoxFilter
          title={t("offer_listing.filter.my_listings.title")}
          disabled={disabled}
          options={[
            {
              name: MY_LISTINGS,
              label: t("offer_listing.filter.my_listings.label"),
              isChecked: myListings,
              onChange: () => dispatch({ type: HANDLE_MY_LISTINGS_CHANGE, payload: !myListings })
            },
          ]}
        />
        <RadioFilter
          className="aggregate-data-filter__box--radio-options"
          title={t('offer_listing.filter.listing_type.title')}
          disabled={disabled}
          options={listingTypeOptions}
          selectedOption={listingType}
          onChange={(value) => {
            dispatch({ type: HANDLE_LISTING_TYPE_CHANGE, payload: value });
            if (value === 'offers' || value === 'orders') {
              setDateRange(THIS_YEAR);
              dispatch({ type: HANDLE_LISTING_DATE_CHANGE, payload: THIS_YEAR });
            }
          }}
        />
        <RadioFilter
          className="aggregate-data-filter__box--radio-options border-b-none"
          title={t('offer_listing.filter.shipping_date_range.title')}
          disabled={disabled}
          options={listingDateRangeOptions}
          selectedOption={dateRange}
          onChange={(value) => {
            setDateRange(value);
            if (value === YOUR_DATE) {
              setIsPickYourDateActive(true);
            } else {
              setIsPickYourDateActive(false);
              dispatch({ type: HANDLE_LISTING_DATE_CHANGE, payload: value });
            }
          }}
        />
        {isPickYourDateActive && (
          <DateRangeFilter
            className="aggregate-data-filter__box--radio-options border-b-none"
            title={t('offer_listing.filter.shipping_date_range.title')}
            disabled={disabled}
            startDate={shippingStartDate}
            endDate={shippingEndDate}
            onStartDateChange={(value) => dispatch({ type: HANDLE_SHIPPING_START_DATE_CHANGE, payload: value })}
            onEndDateChange={(value) => dispatch({ type: HANDLE_SHIPPING_END_DATE_CHANGE, payload: value }) }
          />
        )}
      </div>
      <div className="aggregate-data-filter__box flex justify-between ">
        <h3 className="aggregate-data-filter__box--title aggregate-data-filter__box--title-large">
          {t('offer_listing.filter.title')}
        </h3>
        <Button
          label={t('offer_listing.filter.reset_all_button')}
          className="aggregate-data-filter__box--reset-button"
          disabled={disabled}
          onClick={() => dispatch({ type: HANDLE_RESET_CLICK })}
        />
      </div>
      <CheckBoxFilter
        title={t('offer_listing.filter.container_load.title')}
        disabled={disabled}
        options={[
          {
            name: 'FCL',
            label: t('offer_listing.filter.container_load.options.fcl'),
            isChecked: fcl,
            onChange: () => {
              dispatch({ type: HANDLE_FCL_CONTAINER_LOAD_CHANGE, payload: !fcl });
            },
          },
          {
            name: 'LCL',
            label: t('offer_listing.filter.container_load.options.lcl'),
            isChecked: lcl,
            onChange: () => {
              dispatch({ type: HANDLE_LCL_CONTAINER_LOAD_CHANGE, payload: !lcl });
            },
          },
        ]}
      />
      <CheckBoxFilter
        title={t('offer_listing.filter.nut_style.title')}
        disabled={disabled}
        dispatch={dispatch}
        resetButtonType={HANDLE_NUT_STYLE_RESET_CLICK}
        options={[
          {
            name: STYLE_0,
            label: t('offer_listing.filter.nut_style.options.style_0'),
            isChecked: style0,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_0_CHANGE, payload: !style0 });
            },
          },
          {
            name: STYLE_1,
            label: t('offer_listing.filter.nut_style.options.style_1'),
            isChecked: style1,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_1_CHANGE, payload: !style1 });
            },
          },
          {
            name: STYLE_1_S,
            label: t('offer_listing.filter.nut_style.options.style_1s'),
            isChecked: style1s,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_1S_CHANGE, payload: !style1s });
            },
          },
          {
            name: STYLE_2,
            label: t('offer_listing.filter.nut_style.options.style_2'),
            isChecked: style2,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_2_CHANGE, payload: !style2 });
            },
          },
          {
            name: STYLE_3,
            label: t('offer_listing.filter.nut_style.options.style_3'),
            isChecked: style3,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_3_CHANGE, payload: !style3 });
            },
          },
          {
            name: STYLE_4_L,
            label: t('offer_listing.filter.nut_style.options.style_4l'),
            isChecked: style4l,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_4L_CHANGE, payload: !style4l });
            },
          },
          {
            name: STYLE_4_S,
            label: t('offer_listing.filter.nut_style.options.style_4s'),
            isChecked: style4s,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_4S_CHANGE, payload: !style4s });
            },
          },
          {
            name: STYLE_5,
            label: t('offer_listing.filter.nut_style.options.style_5'),
            isChecked: style5,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_5_CHANGE, payload: !style5 });
            },
          },
          {
            name: STYLE_6,
            label: t('offer_listing.filter.nut_style.options.style_6'),
            isChecked: style6,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_6_CHANGE, payload: !style6 });
            },
          },
          {
            name: STYLE_7,
            label: t('offer_listing.filter.nut_style.options.style_7'),
            isChecked: style7,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_7_CHANGE, payload: !style7 });
            },
          },
          {
            name: STYLE_8,
            label: t('offer_listing.filter.nut_style.options.style_8'),
            isChecked: style8,
            onChange: () => {
              dispatch({ type: HANDLE_NUT_STYLE_8_CHANGE, payload: !style8 });
            },
          },
          {
            name: STYLE_CUSTOM,
            label: t('offer_listing.filter.nut_style.options.custom'),
            isChecked: otherNutStyle,
            onChange: () => {
              dispatch({ type: HANDLE_OTHER_NUT_STYLE_CHANGE, payload: !otherNutStyle });
            },
          },
        ]}
      />
      <CheckBoxFilter
        title={t('offer_listing.filter.nut_grade.title')}
        disabled={disabled}
        dispatch={dispatch}
        resetButtonType={HANDLE_NUT_GRADE_RESET_CLICK}
        options={[
          {
            name: PREMIUM,
            label: t('offer_listing.filter.nut_grade.options.premium'),
            isChecked: premium,
            onChange: () => {
              dispatch({ type: HANDLE_PREMIUM_NUT_GRADE_CHANGE, payload: !premium });
            },
          },
          {
            name: COMMERCIAL,
            label: t('offer_listing.filter.nut_grade.options.commercial'),
            isChecked: commercial,
            onChange: () => {
              dispatch({ type: HANDLE_COMMERCIAL_NUT_GRADE_CHANGE, payload: !commercial });
            },
          },
          {
            name: REJECTED,
            label: t('offer_listing.filter.nut_grade.options.rejected'),
            isChecked: rejected,
            onChange: () => {
              dispatch({ type: HANDLE_REJECTED_NUT_GRADE_CHANGE, payload: !rejected });
            },
          },
        ]}
      />
      <SelectFilter
        title={t('offer_listing.filter.harvest_season.title')}
        placeholderText={t('offer_listing.filter.harvest_season.placeholder')}
        disabled={disabled}
        options={seasons}
        selectedOption={harvestSeason}
        onChange={(value) => dispatch({ type: HANDLE_HARVEST_SEASON_CHANGE, payload: value })}
      />
      <SelectFilter
        title={t('offer_listing.filter.proccessed_season.title')}
        placeholderText={t('offer_listing.filter.proccessed_season.placeholder')}
        disabled={disabled}
        options={seasons}
        selectedOption={processedSeason}
        onChange={(value) => dispatch({ type: HANDLE_PROCESSED_SEASON_CHANGE, payload: value })}
      />
      <BestBeforeFilter
        date={bestBeforeDate}
        disabled={disabled}
        onDateChange={(value) => dispatch({ type: HANDLE_BEST_BEFORE_CHANGE, payload: value })}
      />
      <RangeSlider
        min={Number(minPriceBound)}
        max={Number(maxPriceBound)}
        defaultMinValue={Number(minPriceBound)}
        defaultMaxValue={Number(maxPriceBound)}
        allowCross={false}
        disabled={disabled}
        onAfterChange={handlePriceInputChange}
        onChange={handlePriceInputChange}
        color="success"
        titleText={t('offer_listing.filter.price.title')}
        minInputPlaceholder={t('offer_listing.filter.price.min_price')}
        maxInputPlaceholder={t('offer_listing.filter.price.max_price')}
        className="aggregate-data-filter__box aggregate-data-filter__box--range-slider"
      />
      <RangeSlider
        min={Number(minVolumeBound)}
        max={Number(maxVolumeBound)}
        defaultMinValue={Number(minVolumeBound)}
        defaultMaxValue={Number(maxVolumeBound)}
        allowCross={false}
        disabled={disabled}
        onAfterChange={handleQuantityInputChange}
        onChange={handleQuantityInputChange}
        color="success"
        titleText={t('offer_listing.filter.quantity.title')}
        minInputPlaceholder={t('offer_listing.filter.quantity.min')}
        maxInputPlaceholder={t('offer_listing.filter.quantity.max')}
        className="aggregate-data-filter__box aggregate-data-filter__box--range-slider"
      />
      <CheckBoxFilter
        title={t('offer_listing.filter.payment.title')}
        disabled={disabled}
        dispatch={dispatch}
        resetButtonType={HANDLE_PAYMENT_OPTION_RESET_CLICK}
        options={[
          {
            name: DAP,
            label: t('offer_listing.filter.payment.options.dap'),
            isChecked: dap,
            onChange: () => {
              dispatch({ type: HANDLE_DAP_PAYMENT_OPTION_CHANGE, payload: !dap });
            },
          },
          {
            name: DAYS_30,
            label: t('offer_listing.filter.payment.options.30_days'),
            isChecked: days30,
            onChange: () => {
              dispatch({ type: HANDLE_DAYS_30_PAYMENT_OPTION_CHANGE, payload: !days30 });
            },
          },
          {
            name: DAYS_60,
            label: t('offer_listing.filter.payment.options.60_days'),
            isChecked: days60,
            onChange: () => {
              dispatch({ type: HANDLE_DAYS_60_PAYMENT_OPTION_CHANGE, payload: !days60 });
            },
          },
          {
            name: DAYS_90,
            label: t('offer_listing.filter.payment.options.90_days'),
            isChecked: days90,
            onChange: () => {
              dispatch({ type: HANDLE_DAYS_90_PAYMENT_OPTION_CHANGE, payload: !days90 });
            },
          },
          {
            name: DAYS_180,
            label: t('offer_listing.filter.payment.options.180_days'),
            isChecked: days180,
            onChange: () => {
              dispatch({ type: HANDLE_DAYS_180_PAYMENT_OPTION_CHANGE, payload: !days180 });
            },
          },
          {
            name: CUSTOM_PAYMENT_OPTION,
            label: t('offer_listing.filter.payment.options.custom_payment'),
            isChecked: customPaymentOption,
            onChange: () => {
              dispatch({ type: HANDLE_CUSTOM_PAYMENT_OPTION_CHANGE, payload: !customPaymentOption });
            },
          },
        ]}
      />
      <CheckBoxFilter
        title={t('offer_listing.filter.certifications.title')}
        disabled={disabled}
        dispatch={dispatch}
        resetButtonType={HANDLE_CERTIFICATIONS_RESET_CLICK}
        options={[
          {
            name: 'HACCP',
            label: t('offer_listing.filter.certifications.options.haccp'),
            isChecked: haccp,
            onChange: () => {
              dispatch({ type: HANDLE_HACCP_CERTIFICATION_CHANGE, payload: !haccp });
            },
          },
          {
            name: 'FSSC',
            label: t('offer_listing.filter.certifications.options.fssc'),
            isChecked: fssc,
            onChange: () => {
              dispatch({ type: HANDLE_FSSC_CERTIFICATION_CHANGE, payload: !fssc });
            },
          },
          {
            name: 'BRC',
            label: t('offer_listing.filter.certifications.options.brc'),
            isChecked: brc,
            onChange: () => {
              setBrcLevelsFilterVisible(!brc === true);
              dispatch({ type: HANDLE_BRC_CERTIFICATION_CHANGE, payload: !brc });
            },
          },
        ]}
      />
      {brcLevelsFilterVisible && <RadioFilter
        className="aggregate-data-filter__box--radio-options"
        title={t('offer_listing.filter.brc_levels.title')}
        disabled={disabled}
        options={brcLevelOptions}
        selectedOption={brcLevel}
        onChange={(value) => dispatch({ type: HANDLE_BRC_LEVEL_CHANGE, payload: value })}
      />}
      <SelectFilter
        title={t('offer_listing.filter.origin_region.title')}
        placeholderText={t('offer_listing.filter.origin_region.placeholder')}
        disabled={disabled}
        options={regions}
        selectedOption={region}
        onChange={(value) => dispatch({ type: HANDLE_REGION_CHANGE, payload: value })}
      />
      <CountryFilter
        title={t('offer_listing.filter.origin_country.title')}
        disabled={disabled}
        country={country}
        onChange={(value) => dispatch({ type: HANDLE_COUNTRY_CHANGE, payload: value })}
        onReset={() => dispatch({ type: HANDLE_COUNTRY_CHANGE, payload: null }) }
      />
      {listingType !== OFFERS && (
        <CountryFilter
          title={t('offer_listing.filter.destionation_country.title')}
          disabled={disabled}
          country={destinationCountry}
          onChange={(value) => dispatch({ type: HANDLE_DESTINATION_COUNTRY_CHANGE, payload: value })}
          onReset={() => dispatch({ type: HANDLE_DESTINATION_COUNTRY_CHANGE, payload: null }) }
        />
      )}
      <CheckBoxFilter
        title={t('offer_listing.filter.religion.title')}
        disabled={disabled}
        options={[
          {
            name: HALAAL,
            label: t('offer_listing.filter.religion.options.halaal'),
            isChecked: halaal,
            onChange: () => dispatch({ type: HANDLE_HALAAL_CHANGE, payload: !halaal })
          },
          {
            name: KOSHER,
            label: t('offer_listing.filter.religion.options.kosher'),
            isChecked: kosher,
            onChange: () => dispatch({ type: HANDLE_KOSHER_CHANGE, payload: !kosher })
          },
        ]}
      />
      <h4 className="aggregate-data-filter__box--title aggregate-data-filter__other-filters flex items-center gap-4px">
        {t('offer_listing.filter.other_filters')}
      </h4>
      <CheckBoxFilter
        className='other-filters'
        disabled={disabled}
        options={[
          {
            name: ORGANIC,
            label: t('offer_listing.filter.organic.options.true'),
            isChecked: organic,
            onChange: () => dispatch({ type: HANDLE_ORGANIC_CHANGE, payload: !organic })
          },
        ]}
      />
      <CheckBoxFilter
        className='other-filters'
        disabled={disabled}
        options={[
          {
            name: PASTEURIZED,
            label: t('offer_listing.filter.pasteurized.options.true'),
            isChecked: pasteurized,
            onChange: () => dispatch({ type: HANDLE_PASTEURIZED_CHANGE, payload: !pasteurized }),
          },
        ]}
      />
      <CheckBoxFilter
        className='other-filters other-filters__last border-b-none'
        disabled={disabled}
        options={[
          {
            name: GACC,
            label: t('offer_listing.filter.dnis_certifications.options.gacc'),
            isChecked: gacc,
            onChange: () => {
              dispatch({ type: HANDLE_DNIS_CERTIFICATION_GACC_CHANGE, payload: !gacc });
            },
          },
        ]}
      />
    </div>
  );
};

KernelAggregateDataFilter.defaultProps = {
  priceRange: { min: 0, max: 0 },
  volumeRange: { min: 0, max: 0 },
  disabled: false,
};

KernelAggregateDataFilter.propTypes = {
  filterState: PropTypes.shape({
    myListings: PropTypes.bool,
    listingType: PropTypes.string,
    productType: PropTypes.oneOf([KERNEL_PRODUCT]),
    listingDateRange: PropTypes.string,
    fcl: PropTypes.bool,
    lcl: PropTypes.bool,
    style0: PropTypes.bool,
    style1: PropTypes.bool,
    style1s: PropTypes.bool,
    style2: PropTypes.bool,
    style3: PropTypes.bool,
    style4l: PropTypes.bool,
    style4s: PropTypes.bool,
    style5: PropTypes.bool,
    style6: PropTypes.bool,
    style7: PropTypes.bool,
    style8: PropTypes.bool,
    otherNutStyle: PropTypes.bool,
    nutGrade: PropTypes.string,
    harvestSeason: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
    processedSeason: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
    bestBeforeDate: PropTypes.string,
    premium: PropTypes.bool,
    commercial: PropTypes.bool,
    rejected: PropTypes.bool,
    dap: PropTypes.bool,
    days30: PropTypes.bool,
    days60: PropTypes.bool,
    days90: PropTypes.bool,
    days180: PropTypes.bool,
    customPaymentOption: PropTypes.string,
    haccp: PropTypes.bool,
    fssc: PropTypes.bool,
    brc: PropTypes.bool,
    shippingStartDate: PropTypes.string,
    shippingEndDate: PropTypes.string,
    brcLevel: PropTypes.string,
    region: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
    country: PropTypes.string,
    destinationCountry: PropTypes.string,
    halaal: PropTypes.bool,
    kosher: PropTypes.bool,
    organic: PropTypes.bool,
    pasteurized: PropTypes.bool,
    gacc: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  priceRange: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  volumeRange: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  disabled: PropTypes.bool,
};

export default KernelAggregateDataFilter;
